import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { Box, Typography } from "@mui/material";
import { PropTypes } from 'prop-types';

const Card13 = ({content}) => {
    const template = useSelector(templateSelector);
    const theme = useSelector(themeSelector);
    const fontFamily = useSelector(fontFamilySelector);

    const styles = {
        container: {    
            display: 'table',
            width: '100%'
        },
        number: {
            background: theme.light.primary,
            color: theme.light.default,
            display: 'table-cell',
            verticalAlign: 'middle',
            minwidth: '25%'
        },
        name: {
            clipPath: 'polygon(0% 0%, 0% 100%, 87% 100%, 100% 50%, 87% 0%)',
            background: theme.light.info,
            color: theme.light.primary,
            display: 'table-cell',
            verticalAlign: 'middle',
            minwidth: '75%'
        }
    }
    return(
        <Box style={styles.container}>
            <Box style={{display: 'table-row'}}>
                <Box style={styles.number}>
                    <Typography variant="h2" style={{...getTextStyles(template, "header2", {}), ...{fontFamily: fontFamily.primary, padding: '0 0.5vw'}}}>{content?.number}</Typography>
                </Box>
                <Box style={styles.name}>
                    <Typography variant="h6" style={{...getTextStyles(template, "header6", {}), ...{fontFamily: fontFamily.primary, textTransform: 'uppercase', padding: '0 0.5vw'}}}>{content?.name}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

Card13.propTypes = {
    content: PropTypes.shape({
        number: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })
}

export default Card13;