import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import Card11 from "../Card/Card11";

const Details6 = ({details}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    return(
        <Box style={{paddingTop: '8vw'}}>
            <Typography style={getTextStyles(template, details?.mainTitleStyle ? details?.mainTitleStyle : 'header1', {color: theme.light.primary, fontFamily: fontFamily.primary})}>{details?.mainTitle}</Typography>
            <Typography style={{...getTextStyles(template, details?.subTitleStyle ? details.subTitleStyle : 'header5', {color: theme.light.info, fontFamily: fontFamily.primary}), ...{textAlign: 'center'}}}>{details?.subTitle}</Typography>
            {details?.teamDetails && <Box style={{padding: '5vw 0'}}>
                {details?.teamDetails.map((member, index)=>(
                    <Card11 content={member} index={index}/>
                ))}
            </Box>}
        </Box>
    )
}

export default Details6;