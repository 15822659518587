import { Box, Grid, Typography } from "@mui/material";
import { getTextStyles } from "../../style/textStyles";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { useLocation } from "react-router-dom";
import { authTokenSelector } from "../../services/TemplateSlice";

const Card20 = ({content, onDoubleClick, onDragEnd, onDragOver}) => {
    const template = useSelector(templateSelector);
    const theme = useSelector(themeSelector);
    const fontFamily = useSelector(fontFamilySelector);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authToken = useSelector(authTokenSelector);

    const styles = {
        container: {
            padding: '2vw',  
            borderImage: `repeating-linear-gradient(${theme.light.default}, ${theme.light.secondary}, ${theme.light.default}) 25% stretch`,
            borderWidth: window.innerWidth > 900 ? '0px 2px 0px 0px' : '0px 0px 0px 0px',
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }

    return(
        <Box style={styles.container}>
            <Grid container 
            onDoubleClick={onDoubleClick}
            style={{cursor: Boolean(authToken) && authToken === queryParams.get('authToken') ? 'move' : 'default'}}
            draggable={Boolean(authToken) && authToken === queryParams.get('authToken')} 
            onDragEnd={onDragEnd} 
            onDragOver={onDragOver}
            >
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{color: theme.light.primary, fontFamily: fontFamily.secondary, fontSize: window.innerWidth > 900 ? '3vw' : 'calc(55px + 1vw)'}}}>{content?.index < 9 ? `0${content?.index + 1}.` : content?.index + 1}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                    <Typography variant="h2" style={{...getTextStyles(template, 'header2', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, fontWeight: '900'}}}>{content?.company}</Typography>
                    <Typography variant="h5" style={{...getTextStyles(template, 'header5', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{content?.role}</Typography>
                    <Typography variant="h6" style={{...getTextStyles(template, 'header6', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{content?.year}</Typography>
                    <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{content?.description}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

Card20.propTypes = {
    content: PropTypes.shape({
        index: PropTypes.number.isRequired,
        company: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        year: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
    })
}

export default Card20;