import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
import { resourcesSelector } from "../../services/TemplateSlice";
import { setEditData } from "../../services/EditingSlice";

const Card17 = ({content, doubleClick}) => {
    const template = useSelector(templateSelector);
    const theme = useSelector(themeSelector);
    const fontFamily = useSelector(fontFamilySelector);
    const resources = useSelector(resourcesSelector);

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const openEditImage = (img) => {
        dispatch(setEditData({
            editType: 'image',
            editContent: img,
            editTitle: 'Edit Picture',
            path: img
        }))
    }

    const styles= {
        outer: {
            background: theme.light.tertiary,
            width: '90%',
            position: 'relative'
        },
        corner: {
            position: 'absolute',
            right: 0,
            top:0,
            left: 'auto',
            width: '70px',
            height: '70px',
            clipPath: 'polygon(100% 0, 0 0, 100% 100%)',
            background: theme.light.primary
        },
        imageWrap: {
            height: '300px',
            display: 'flex',
            justifyContent: 'center'
        },
        image: {
            width: '100%',
            height: '300px',
            objectFit: 'scale-down',
            objectPosition: 'center',
            paddingTop: '30px'
        },
        content: {
            background: theme.light.default,
            color: theme.light.info,
            width: '80%',
            height: 'auto',
            padding: '2vw',
            marginTop: '30px'
        },
        technology: {
            background: theme.light.primary,
            width: '85%',
            marginBottom: '5vw',
            display: window.innerWidth > 600 ? 'flex' : 'block',
            alignItems: 'center',
            padding: '1.5vw 2vw',
            position: 'relative'
        },
        button: {
            position: window.innerWidth > 600 ? 'absolute': '',
            margin: window.innerWidth > 600 ? 0: '3vw 0',
            background: theme.light.info,
            borderRadius: '10px',
            top: '4vw',
            right: '3vw',
            left: 'auto',
            padding: '0 1vw'
        }
    }

    return(
        <Box style={styles.outer} boxShadow={8}>
            <Box style={styles.imageWrap} onDoubleClick={() => openEditImage(content?.image)}>
                <img src={resources[content?.image]} alt="project" style={styles.image}></img>
            </Box>
            <Box style={styles.corner}></Box>
            <Box style={styles.content} onDoubleClick={() => {
                if(typeof(doubleClick) === 'function'){
                    doubleClick();
                }
            }}>
                <Typography variant="h2" style={{...getTextStyles(template, 'header2', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, textAlign: 'left', fontWeight: 'bolder', paddingBottom: '2vw'}}}>{content?.name}</Typography>
                <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, textAlign: 'left'}}}>{content?.intro}</Typography>
            </Box>
            <Box style={styles.technology} onDoubleClick={() => {
                if(typeof(doubleClick) === 'function'){
                    doubleClick();
                }
            }}>
                <Typography variant="h6" style={{...getTextStyles(template, 'header6', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, opacity: 1}}}>{content?.technologies}</Typography>
                <Box style={styles.button}>
                    <Button style={{background: 'transparent', color: theme.light.default, fontFamily: fontFamily.primary, fontWeight: '800'}} onClick={() => {navigate(content?.link)}}>{content?.btnText}</Button>
                </Box>
            </Box>
        </Box>
    )
}

Card17.propTypes = {
    content: PropTypes.shape({
        name: PropTypes.string.isRequired,
        intro: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        technologies: PropTypes.string.isRequired,
        btnText: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
    })
}

export default Card17;