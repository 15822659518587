import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { Box, Grid, Typography } from "@mui/material";
import { getTextStyles } from "../../style/textStyles";
import { generateEditPath } from "../../editingComponents/Helpers";
import { setEditData } from "../../services/EditingSlice";

const Contact5 = ({details}) => {
    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)

    const dispatch = useDispatch();

    const styles = {
        container: {
            width: '100%',
            height: '500px',
            position: 'relative',
            zIndex: 0
        },
        clipped: {
            width: '100%',
            height: '100%',
            clipPath: 'polygon(0 80%, 0% 100%, 100% 100%, 100% 0%)',
            background: theme.light.secondary
        },
        overlay: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1
        },
        education:{
            height: window.innerWidth > 900 ? '70%': '100%',
            background: theme.light.default,
            boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
            padding: '2vw',
            textAlign: 'left',
            margin: '0 3vw'
        }
    }

    const openEditEducation = (ele, i) => {
        const editingFields = [
            {key: 'college', label: 'College/University Name'},
            {key: 'degree', label: 'Degree Name'},
            {key: 'stream', label: 'Field Name'},
            {key: 'GPA', label: 'GPA'}
        ];
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: ele,
            editingFields: editingFields,
            editTitle: 'Edit Education Details',
            selector: 'contactDetails',
            path: generateEditPath(`educationInfo.content[${i}]`, editingFields)
        }))
    }

    const openEdit = (ele, i) => {
        const editingFields = [
            {key: 'text', label: ele.heading},
        ];
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: ele,
            editingFields: editingFields,
            editTitle: `Edit ${ele.heading} Details`,
            selector: 'contactDetails',
            path: generateEditPath(`contactInfo[${i}]`, editingFields)
        }))
    }

    const openEditName = (name) => {
        const editingFields = [
            {key: 'name', label: 'Name'},
        ];
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: {
                name: name
            },
            editingFields: editingFields,
            editTitle: `Edit Name`,
            selector: 'contactDetails',
            path: 'name'
        }))
    }

    return(
        <Box style={styles.container}>
            <Box style={styles.clipped}></Box>
            <Grid container style={styles.overlay}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box style={styles.education}>
                        <Typography variant="h2" style={{...getTextStyles(template, 'header2', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{details?.educationInfo?.title}</Typography>
                        <Box>
                            {details?.educationInfo?.content?.map((ele, i) => (
                                <Box style={{padding: '2vw 0'}} key={i} onDoubleClick={() => {
                                    openEditEducation(ele, i);
                                }}>
                                    <Typography variant="h4" style={{...getTextStyles(template, 'header4', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele?.college}</Typography>
                                    <Typography variant="h5" style={{...getTextStyles(template, 'header5', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele?.degree}</Typography>
                                    <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele?.stream}</Typography>
                                    {ele?.GPA ? <Typography variant="h6" style={{...getTextStyles(template, 'header6', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele?.GPA}</Typography> : ''}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} style={{paddingTop: window.innerWidth > 900 ? '12%' : '5%'}}>
                    <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}} onDoubleClick={() => openEditName(details?.name)}>{details?.name}</Typography>
                    <Box style={{padding: '2vw'}}>
                        {details?.contactInfo?.map((ele, i) => (
                            <Box key={i} onDoubleClick={() => openEdit(ele, i)}>
                                {ele.heading === 'Email' ? 
                                <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, cursor: 'pointer'}}} onClick={() => window.location.href = `mailto:${ele.text}`}>{ele?.text}</Typography> : 
                                (ele.heading === 'Phone' ? 
                                    <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, cursor: 'pointer'}}} onClick={() => window.location.href = `tel:${ele.text}`}>{ele?.text}</Typography> :
                                    <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele?.text}</Typography>
                                )
                                }
                            </Box>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Contact5;