import { Box, Divider, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import Card13 from "../Card/Card13";
import { resourcesSelector } from "../../services/TemplateSlice";
import { setEditData } from "../../services/EditingSlice";

const About2 = ({details}) => {
    const template = useSelector(templateSelector);
    const resources = useSelector(resourcesSelector);
    const theme = useSelector(themeSelector);
    const fontFamily = useSelector(fontFamilySelector);

    const dispatch = useDispatch();

    const styles = {
        about: {
            padding: '2vw 2vw 5vw 2vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        imageContainer: {
            width: '100%',
            maxWidth: 'calc(300px + 1vw)',
            height: 'calc(300px + 1vw)',
            background: `linear-gradient(300deg, ${theme.light.tertiary}, ${theme.light.info})`,
            borderRadius: '100%',
            borderLeft: 0,
            padding: '4px 4px 0 0'
        },
        image: {
            width: '90%',
            height: '90%',
            borderRadius: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        },
        overlay: {
            borderRadius: '100%',
            width: '100%',
            height: 'calc(300px + 1vw)',
            backgroundColor: theme.light.default,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        skills: {
            backgroundColor: theme.light.default
        },
        bar: {
            marginTop: '5px',
            width: '100%',
            height: '6px',
            backgroundColor: '#e9ecef',
            borderRadius: '10px'
        },
        fill: {
            backgroundColor: theme.light.secondary,
            height: '6px',
            borderRadius: '10px'
        },
        projects: {
            backgroundColor: theme.light.tertiary
        },
        education: {
            backgroundColor: theme.light.primary
        },
        aboutContainer: {
            textAlign: 'center',
            padding: '2vw 0'
        },
    }

    const openEditSkills = (skill, i) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: {
                skill: skill.skill,
                level: skill.level
            },
            editingFields: [
                {key: 'skill', label: 'Skill'},
                {key: 'level', label: 'Level'}
            ],
            editTitle: 'Edit Skill',
            path: `sections[0].skills.content[${i}].skill,sections[0].skills.content[${i}].level`
        }))
    }

    const openEditEducation = (ele, i) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: {
                name: ele.name,
                degree: ele.degree,
                field: ele.field,
                gpa: ele.gpa
            },
            editingFields: [
                {key: 'name', label: 'College/University Name'},
                {key: 'degree', label: 'Degree Name'},
                {key: 'field', label: 'Field Name'},
                {key: 'gpa', label: 'GPA'}
            ],
            editTitle: 'Edit Education Details',
            path: `sections[0].education.content[${i}].name,sections[0].education.content[${i}].degree,sections[0].education.content[${i}].field,sections[0].education.content[${i}].gpa`
        }))
    }

    return(
        <Grid container>
            <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary, textAlign: 'center', paddingTop: '10vw', width: '100%'}}}>About Me</Typography>
            {details?.about && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container style={styles.about}>
                    <Grid item xs={12} sm={12} md={4} lg={4} style={{display: 'flex', justifyContent: 'center'}}>
                        <Box style={styles.imageContainer}>
                            <Box style={styles.overlay}>
                                <img alt="about" src={resources[details.about.image]} style={styles.image} onDoubleClick={() => {
                                    dispatch(setEditData({
                                        editType: 'image',
                                        editContent: details.about.content,
                                        editTitle: 'Edit About Me',
                                        path: details.about.image
                                    }))
                                }}/>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} style={{padding: '5vw'}} onDoubleClick={() => {
                        dispatch(setEditData({
                            editType: 'text',
                            regenerateSection: 'ABOUT_ME',
                            editContent: details.about.content,
                            editTitle: 'Edit About Me',
                            path: 'sections[0].about.content'
                        }))
                    }}>
                        {details.about.content?.split('\n').map((ele, i) => (
                            <Typography key={i} variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary}}}>{ele}</Typography> 
                        ))}
                    </Grid>
                </Grid>
            </Grid>}
            {details?.skills && <Grid item xs={12} sm={12} md={details?.achievements?.length > 0 ? 4 : 6} lg={details?.achievements?.length > 0 ? 4 : 6} style={styles.skills}>
                <Box style={{padding: '2vw'}}>
                    <Typography variant="h4" style={{...getTextStyles(template, "header4", {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary, textTransform: 'uppercase'}}}>{details.skills.title}</Typography>
                    <Divider style={{backgroundColor: theme.light.primary}}/>
                    <Box style={{padding: '2vw 0'}}>
                        {details.skills.content?.map((ele, i) => (
                            <Box key={i} onDoubleClick={() => openEditSkills(ele, i)}>
                                <Typography variant="body" style={{...getTextStyles(template, "body", {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary}}}>{ele.skill}</Typography>
                                <Box style={styles.bar}>
                                    <Box style={{...styles.fill, ...{width: ele.level}}}></Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Grid>}
            {details?.achievements && details?.achievements?.length > 0 && <Grid item xs={12} sm={12} md={4} lg={4} style={styles.projects}>
                {details.achievements?.map((ele, i) => (
                    <Box style={{padding: '2vw'}} key={i}>
                        <Card13 content={ele}/>
                    </Box>
                ))}
            </Grid>}
            {details?.education && <Grid item xs={12} sm={12} md={details?.achievements?.length > 0 ? 4 : 6} lg={details?.achievements?.length > 0 ? 4 : 6} style={styles.education}>
                <Box style={{padding: '2vw'}}>
                    <Typography variant="h4" style={{...getTextStyles(template, "header4", {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, textTransform: 'uppercase'}}}>{details.education.title}</Typography>
                    <Divider style={{backgroundColor: theme.light.info}}/>
                    <Box style={{padding: '2vw 0'}}>
                        {details.education.content?.map((ele, i) => (
                            <Box style={{padding: '1vw 0.5vw'}} key={i} onDoubleClick={() => openEditEducation(ele, i)}>
                                <Typography variant="h5" style={{...getTextStyles(template, "header5", {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele.name}</Typography>
                                <Typography variant="h6" style={{...getTextStyles(template, "header6", {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele.degree} in {ele.field}</Typography>
                                <Typography variant="h6" style={{...getTextStyles(template, "header6", {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele.year}</Typography>
                                <Typography variant="body" style={{...getTextStyles(template, "body", {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>GPA: {ele.gpa}</Typography>
                                <Divider style={{backgroundColor: theme.light.info, opacity: '0.5', marginTop: '1vw'}}/>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Grid>}
        </Grid>
    )
}

export default About2;