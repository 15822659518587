import { GitHub, Instagram, LinkedIn, YouTube } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { motion } from "framer-motion";
import { resourcesSelector } from "../../services/TemplateSlice";
import { setEditData } from "../../services/EditingSlice";
import { generateEditPath } from "../../editingComponents/Helpers";

const Card19 = ({content}) => {
    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const resources = useSelector(resourcesSelector);

    const dispatch = useDispatch();

    const styles = {
        about: {
            position: 'absolute',
            background: theme.light.tertiary,
            width: '100%',
            height: 'auto',
            top: '7%',
            left: '40%',
            zIndex: 1,
            border: `2px solid ${theme.light.tertiary}`,
            float: 'left'
        },
        imageWrap: {
            display: 'flex',
            justifyContent: 'center',
            padding: '2vw'
        },
        image: {
            width: '180px',
            height: '180px',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: '10px 60px',
        },
        content: {
            padding: '1vw',
        },
        contact: {
            display: 'flex',
            flexDirection: 'column',
            padding: '2vw'
        },
        button: {
            background: theme.light.default,
            display: 'flex',
            justifyContent: 'center',
            padding: '0.5vw 0'
        },
        icon: {
            background: 'transparent',
            color: theme.light.primary,
        }
    }

    const handleClick = (link) => {
        window.location.href = link
    }

    const openEditImage = () => {
        dispatch(setEditData({
            editType: 'image',
            editTitle: 'Edit Image',
            path: 'PROFILE_IMAGE'
        }))
    }

    const openEdit = (ele, i) => {
        const editingFields = [
            {key: 'name', label: 'Name'},
            {key: 'designation', label: 'Desingation'},
            {key: 'email', label: 'Email ID'},
            {key: 'phone', label: 'Phone Number'},
            {key: 'linkedInLink', label: 'LinkedIn Link'},
            {key: 'instagramLink', label: 'Instagram Link'},
            {key: 'youtubeLink', label: 'Youtube Link'},
            {key: 'githubLink', label: 'Github Link'}
        ];
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: ele,
            editingFields: editingFields,
            editTitle: 'Edit Education Details',
            path: generateEditPath('sections[0].contact', editingFields)
        }))
    }

    return(
        <motion.div
            initial={{x: "-300px", y: '40px', opacity: 0}}
            animate={{ x: 0, y: '40px', opacity: 1 }}
            exit={{ x: "-300px", y: '40px', opacity: 0 }}
            transition={{duration: 0.7}}
        >
            <Box style={window.innerWidth > 900 ? styles.about : {background: theme.light.tertiary, marginTop: '40px', border: `2px solid ${theme.light.tertiary}`}}>
                <Box style={styles.imageWrap} onDoubleClick={() => openEditImage()}>
                    <img src={resources[content?.about?.image]} alt="about" style={styles.image}/>
                </Box>
                <Box style={styles.content} onDoubleClick={() => openEdit(content?.contact)}>
                    <Typography variant="h2" style={{...getTextStyles(template, 'header2', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary, textAlign: 'center'}}}>{content?.contact?.name}</Typography>
                    <Typography variant="h3" style={{...getTextStyles(template, 'header3', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary, textAlign: 'center', opacity: 0.6}}}>{content?.contact?.designation}</Typography>
                </Box>
                <Box style={styles.contact} onDoubleClick={() => openEdit(content?.contact)}>
                    <Typography variant="h6" style={{...getTextStyles(template, 'header6', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, textAlign: 'center', paddingBottom: '1vw', cursor:'pointer'}}} onClick={() => window.location.href = `mailto:${content?.contact?.email}`}>{content?.contact?.email}</Typography>
                    <Typography variant="h6" style={{...getTextStyles(template, 'header6', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, textAlign: 'center', cursor: 'pointer'}}}  onClick={() => window.location.href = `tel:${content?.contact?.phone}`}>{content?.contact?.phone}</Typography>
                </Box>
                <Box style={styles.button} onDoubleClick={() => openEdit(content?.contact)}>
                    <Button style={styles.icon} onClick={() => handleClick(content?.contact?.linkedInLink)}><LinkedIn style={{fontSize: 'calc(10px + 1vw)'}}></LinkedIn></Button>
                    <Button style={styles.icon} onClick={() => handleClick(content?.contact?.instagramLink)}><Instagram style={{fontSize: 'calc(10px + 1vw)'}}></Instagram></Button>
                    <Button style={styles.icon} onClick={() => handleClick(content?.contact?.youtubeLink)}><YouTube style={{fontSize: 'calc(10px + 1vw)'}}></YouTube></Button>
                    <Button style={styles.icon} onClick={() => handleClick(content?.contact?.githubLink)}><GitHub style={{fontSize: 'calc(10px + 1vw)'}}></GitHub></Button>
                </Box>
            </Box>
        </motion.div>
    )
}

export default Card19;