import Navigation1 from "./Navigation1";
import Navigation3 from "./Navigation3";

const Navigation = ({navDetails, refs, navStyles}) => {
    switch(navDetails?.mainSelector){
        case 'NAV1': return(<Navigation1 navDetails={navDetails} sections={refs} navStyles={navStyles}/>)
        case 'NAV3': return(<Navigation3 navDetails={navDetails} navStyles={navStyles}/>)
        default: return(<Navigation1 navDetails={navDetails} sections={refs} navStyles={navStyles}/>)
    }
}

export default Navigation;