import Home from "../Home";
import About from "../About";
import Details2 from "../Details/Details2";
import Details3 from "../Details/Details3";
import Details6 from "../Details/Details6";
import Details5 from "../Details/Details5";
import Details7 from "../Details/Details7";
import Details8 from "../Details/Details8";
import Details9 from "../Details/Details9";
import Details10 from "../Details/Details10";

const Template3 = ({templateDetails, screen, refs}) => {

    switch(screen?.selector){
        case 'HOME': return(<Home homeDetails={templateDetails[screen?.objectKey]} homeType={'HOME1'}/>)
        case 'ABOUT': return(<About aboutDetails={templateDetails[screen?.objectKey]} aboutType={'ABOUT3'}/>)
        case 'PLATFORM': return(<Details2 details={templateDetails[screen?.objectKey]}/>)
        case 'STAKE_HOLDERS': return(<Details3 details={templateDetails[screen?.objectKey]}/>)
        case 'TEAM': return(<Details6 details={templateDetails[screen?.objectKey]}/>)
        case 'NEWS': return(<Details5 details={templateDetails[screen?.objectKey]}/>)
        case 'CONTACT': return(<Details7 details={templateDetails[screen?.objectKey]}/>)
        case 'ABOUT_STAKE_HOLDERS': return(<Details8 details={templateDetails[screen?.objectKey]}/>)
        case 'ABOUT_NEWS': return(<Details9 details={templateDetails[screen?.objectKey]}/>)
        case 'ABOUT_PLATFORM': return(<Details10 details={templateDetails[screen?.objectKey]}/>)
        default: return(<Home homeDetails={templateDetails[screen?.objectKey]} homeType={'HOME1'}/>)
    }

}

export default Template3;