import { Box, Grid } from "@mui/material";
import Sections from "../Sections";

const Template6 = ({templateDetails, screen, refs}) => {
    return(
        <Box>
            {templateDetails?.sections?.map((ele, i) => (
            <Grid container key={i} ref={ref => { refs.current[i] = ref }} style={{paddingTop: '50px'}}>
                <Grid item xs={12} md={12} lg={12}>
                    <Sections sectionDetails={ele} />
                </Grid>
            </Grid>
            ))}
        </Box>
    )
}

export default Template6;