import { Box, Grid, IconButton, Typography } from "@mui/material";
import { getTextStyles } from "../../style/textStyles";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import Card16 from "../Card/Card16";
import { useLocation } from "react-router-dom";
import { authTokenSelector } from "../../services/TemplateSlice";
import { setEditData } from "../../services/EditingSlice";
import { Add } from "@mui/icons-material";

const Experience4 = ({details}) => {
    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authToken = useSelector(authTokenSelector);
    

    const openEdit = (ele, i, arrayContent) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: ele,
            editSubType: typeof(i) === 'number' ? '' : 'array',
            arrayContent: arrayContent,
            arrayIndex: i,
            editingFields: [
                {key: 'company', label: 'Company'},
                {key: 'role', label: 'Role Name'},
                {key: 'description', label: 'Description'},
                {key: 'year', label: 'Duration'},
            ],
            editTitle: 'Edit Experience',
            path: typeof(i) === 'number' ? `sections[1].experiences[${i}].company,sections[1].experiences[${i}].year,sections[1].experiences[${i}].role,sections[1].experiences[${i}].description` : `sections[1].experiences`,
            deletePath: `sections[1].experiences`,
        }))
    }

    return(
        <Box>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{fontFamily: fontFamily.primary, color: theme.light.info}}}>{details?.title}
                    {Boolean(authToken) && authToken === queryParams.get('authToken') && <IconButton style={{marginBottom: 10, color: '#000', marginLeft: 10}} onClick={() => openEdit(undefined, undefined, details?.experiences)}><Add/></IconButton>}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box style={{padding: '3vw 10vw'}}>
                        <Card16 content={details?.experiences}/>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Experience4;