import { Box, IconButton, Typography } from "@mui/material";
import Card24 from "../Card/Card24";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { useLocation } from "react-router-dom";
import { authTokenSelector, editPortfilio } from "../../services/TemplateSlice";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import { setEditData } from "../../services/EditingSlice";
import { shiftElementsByPosition } from "../../editingComponents/Helpers";

const Experience6 = ({details}) => {
    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authToken = useSelector(authTokenSelector);
    const [dragState, setDragState] = useState(null);
    const dispatch = useDispatch();

    const openEdit = (ele, i, arrayContent) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: ele,
            editSubType: typeof(i) === 'number' ? '' : 'array',
            arrayContent: arrayContent,
            arrayIndex: i,
            editingFields: [
                {key: 'company', label: 'Company'},
                {key: 'role', label: 'Role Name'},
                {key: 'description', label: 'Description'},
                {key: 'year', label: 'Duration'},
            ],
            editTitle: 'Add Experience',
            path: typeof(i) === 'number' ? `sections[1].experiences[${i}].company,sections[1].experiences[${i}].year,sections[1].experiences[${i}].role,sections[1].experiences[${i}].description` : `sections[1].experiences`,
            deletePath: `sections[1].experiences`,
        }))
    }
    
    return(
        <Box>
            <Box>
                <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{color: theme.light.primary, fontFamily: fontFamily.secondary, textAlign: 'center'}}}>{details?.title}
                {Boolean(authToken) && authToken === queryParams.get('authToken') && <IconButton style={{marginBottom: 10, color: '#000', marginLeft: 10}} onClick={() => openEdit(undefined, undefined, details?.experiences)}><Add/></IconButton>}
                </Typography>
            </Box>
            <Box style={{padding: '5vw 3vw 0 3vw'}}>
                <Card24 content={details}
                    draggable={Boolean(authToken) && authToken === queryParams.get('authToken')}
                    onDoubleClick={(ele, i) => {openEdit(ele, i, details?.experiences)}}
                    onDragEnd={(ele, i) => {
                        dispatch(editPortfilio(`sections[1].experiences`, shiftElementsByPosition(details?.experiences, i + 1, dragState + 1), queryParams.get('authToken'), null, 'array'));
                    }}
                    onDragOver={(ele, i) => setDragState(i)}
                />
            </Box>
        </Box>
    )
}

export default Experience6;