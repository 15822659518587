import { Box, IconButton, Typography } from "@mui/material";
import Card14 from "../Card/Card14";
import { getTextStyles } from "../../style/textStyles";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { setEditData } from "../../services/EditingSlice";
import { authTokenSelector, editPortfilio } from "../../services/TemplateSlice";
import { useLocation } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { shiftElementsByPosition } from "../../editingComponents/Helpers";

const Project2 = ({details}) => {
    const template = useSelector(templateSelector);
    const theme = useSelector(themeSelector);
    const fontFamily = useSelector(fontFamilySelector);
    const authToken = useSelector(authTokenSelector);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [dragState, setDragState] = useState(null);

    const dispatch = useDispatch();

    const openEdit = (ele, i, arrayContent) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: {
                name: ele.name !== undefined ? ele.name : '',
                technologies: ele.technologies !== undefined ? ele.technologies : '',
                description: ele.description !== undefined ? ele.description : '',
                image: ele.length !== undefined ? 'PROJECT_IMAGE_' + ele.length : null,
            },
            editSubType: typeof(i) === 'number' ? '' : 'array',
            arrayContent: arrayContent === undefined ? ele : arrayContent,
            arrayIndex: i,
            editingFields: [
                {
                    key: 'name',
                    label: 'Project Name',
                },
                {
                    key: 'technologies',
                    label: 'Used Technologies'
                },
                {
                    key: 'description',
                    label: 'Project Description'
                }
            ],
            editTitle: 'Edit Project Details',
            path:  typeof(i) === 'number' ? `sections[2].content[${i}].name,sections[2].content[${i}].technologies,sections[2].content[${i}].description` : `sections[2].content`,
            deletePath: `sections[2].content`,
        }))
    }

    return(
        <>
            {details && <Box>
                <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary, textAlign: 'center', padding: '4vw 0', width: '100%'}}}>{details?.title}
                {Boolean(authToken) && authToken === queryParams.get('authToken') && <IconButton style={{marginBottom: 10, color: '#000', marginLeft: 10}} onClick={() => openEdit(details?.content)}><Add/></IconButton>}
                </Typography>
                <Box style={{padding: '2vw'}}>
                    {details?.content?.map((ele, i) => (
                        <Card14 
                            content={ele} 
                            key={i} 
                            index={i} 
                            doubleClick={() => openEdit(ele, i, details?.content)}
                            draggable={Boolean(authToken) && authToken === queryParams.get('authToken')} 
                            onDragEnd={(event) => {
                                dispatch(editPortfilio(`sections[2].content`, shiftElementsByPosition(details?.content, i + 1, dragState + 1), queryParams.get('authToken'), null, 'array'));
                            }} 
                            onDragOver={() => setDragState(i)}
                        />
                    ))}
                </Box>
            </Box>}
        </>
    )
}

export default Project2;