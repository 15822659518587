import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { themeSelector } from "../../services/ThemeSlice";
import { PropTypes } from "prop-types";

const Card15 = ({image}) => {
    const theme = useSelector(themeSelector);

    const styles = {
        container: {
            width: '70%',
            maxWidth:  'calc(200px + 1vw)',
            height: 'calc(200px + 1vw)',
            padding: '20px',
            border: '10px solid transparent',
            background: `linear-gradient(${theme.light.default} 0 0) padding-box, repeating-conic-gradient(${theme.light.secondary} 0 25%,transparent 0 50%) border-box`
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'scale-down',
            objectPosition: 'center'
        }
    }

    return(
        <Box style={styles.container}>
            <img alt="card15" src={image} style={styles.image}/>
        </Box>
    )
}

Card15.propTypes = {
    image: PropTypes.string.isRequired
}

export default Card15;