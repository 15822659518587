export function shiftElementsByPosition(jsonArray, positionToMove, newPosition) {
    if (positionToMove <= 0 || positionToMove > jsonArray.length) {
      console.error("Invalid positionToMove value.");
      return jsonArray;
    }
  
    if (newPosition <= 0 || newPosition > jsonArray.length) {
      console.error("Invalid newPosition value.");
      return jsonArray;
    }
  
    const shiftedArray = jsonArray.slice(); // Create a copy of the original array
    const elementToMove = shiftedArray.splice(positionToMove - 1, 1)[0]; // Remove the element from its original position
    shiftedArray.splice(newPosition - 1, 0, elementToMove); // Insert the element at the new position
  
    return shiftedArray;
}

export function generateEditPath(basePath, editFields) {
  if(!editFields){
    return '';
  }
  if(typeof(editFields) !== 'object'){
    return '';
  }
  let finalPath = '';
  editFields.forEach(item => {
    finalPath += `${basePath}${basePath !== '' ? '.' : ''}${item.key},`
  })
  return finalPath.slice(0, -1);
}