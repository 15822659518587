import { Box, Button, Grid, Typography } from "@mui/material";
import { colorTheme } from "./colorTheme";

const ErrorPage = () => {
    const styles = {
        textWrap: {
            position: window.innerWidth <= 900 ? 'absolute' : 'relative',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '5vw',
            textAlign: 'center'
        },
        text: {
            fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif`,
        },
        header1: {
            fontSize: 'calc(120px + 1vw)',
            lineHeight: '1',
            color: colorTheme.primary,
            fontWeight: '500'
        },
        header2: {
            fontSize: 'calc(30px + 1vw)',
            lineHeight: '1.5',
            color: colorTheme.info
        },
        header5: {
            fontSize: 'calc(13px + 0.5vw)',
            lineHeight: '2',
            color: colorTheme.secondary
        },
        body: {
            fontSize: 'calc(10px + 0.5vw)',
            color: colorTheme.info
        },
        button: {
            color: 'white',
            background: colorTheme.primary,
            borderRadius: '50px',
            marginTop: '1vw'
        },
        outer: {
            background: `linear-gradient(45deg, #d8e0fe 2%, white)`,
            border: `1px solid #d8e0fe`,
            borderRadius: '100%',
            width: '90%'
        },
        imageWrap: {

        }

    }

    return(
        <Box style={{padding: '5vw', position: 'relative'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box style={{...styles.outer, ...{height: '400px'}}}>
                                    </Box>
                                </Grid>
                                
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <Box style={{...styles.outer, ...{height: '200px'}}}>
                                    </Box>
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                    <Box style={{...styles.outer, ...{marginTop: '5vw', height: '50px'}}}>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <Box style={{...styles.outer, ...{height: '120px'}}}>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Box style={styles.textWrap}>
                        <Typography variant="h1" style={{...styles.text, ...styles.header1}}>404</Typography>
                        <Typography variant="h2" style={{...styles.text, ...styles.header2}}>Oops! We couldn't find the page you're searching for.</Typography>
                        <Typography variant="h5" style={{...styles.text, ...styles.header5}}>The webpage seems to be missing. Please re-check if the address you typed is correct.</Typography>
                        <Typography variant="body1" style={{...styles.text, ...styles.body}}>To create your own portfolio</Typography>
                        <Button variant="contained" style={styles.button} onClick={() => window.location.href = 'https://resumlink.com'}>Click Here</Button>
                    </Box>
                </Grid>
            </Grid>
            <Box>
                
            </Box>
        </Box>
    )
}

export default ErrorPage;
