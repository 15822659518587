import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { themeSelector } from "../../services/ThemeSlice";
import { resourcesSelector } from "../../services/TemplateSlice";

const Card3 = ({image}) => {
    const theme = useSelector(themeSelector);
    const resources = useSelector(resourcesSelector);
    
    const styles = {
        container: {
            background: 'transparent',
            padding: '20px',
            width: '40vmin',
            height: '30vmin',
            border: '8px solid transparent',
            borderImage: `repeating-linear-gradient(55deg, ${theme.light.info} 25%, ${theme.light.tertiary} 80%) 25`
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'scale-down',
            objectPosition: 'center'
        }
    }

    return(
        <Box style={styles.container} id="test">
            <img alt='project' src={resources !== undefined && resources[image]} style={styles.image}/>
        </Box>
    )
}

export default Card3;