import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import Card25 from "../Card/Card25";
import { getTextStyles } from "../../style/textStyles";
import { useLocation } from "react-router-dom";
import { authTokenSelector, editPortfilio } from "../../services/TemplateSlice";
import { useState } from "react";
import { setEditData } from "../../services/EditingSlice";
import { Add } from "@mui/icons-material";
import { shiftElementsByPosition } from "../../editingComponents/Helpers";

const Project6 = ({details}) => {
    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authToken = useSelector(authTokenSelector);
    const [dragState, setDragState] = useState(null);

    const dispatch = useDispatch();

    const openEdit = (details, i, arrayContent) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: details,
            editSubType: typeof(i) === 'number' ? '' : 'array',
            arrayContent: arrayContent,
            arrayIndex: i,
            editingFields: [
                {key: 'name', label: 'Project Name'},
                {key: 'intro', label: 'Description'},
                {key: 'technologies', label: 'Technologies Used'},
                {key: 'description', label: 'More on the project'}
            ],
            editTitle: 'Edit Project',
            path: typeof(i) === 'number' ? `sections[2].projects[${i}].name,sections[2].projects[${i}].intro,sections[2].projects[${i}].technologies,sections[2].projects[${i}].description` : 'sections[2].projects',
            deletePath: `sections[2].projects`,
        }))
    }

    return(
        <Box>
            <Box>
                <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{color: theme.light.primary, fontFamily: fontFamily.secondary, textAlign: 'center'}}}>{details?.title}
                {Boolean(authToken) && authToken === queryParams.get('authToken') && <IconButton style={{marginBottom: 10, color: '#000', marginLeft: 10}} onClick={() => openEdit(undefined, undefined, details?.projects)}><Add/></IconButton>}
                </Typography>
            </Box>
            {details?.projects?.map((ele, i) => (
                <Box key={i} style={{padding: '0 5vw', margin: '3vw 0', cursor: Boolean(authToken) && authToken === queryParams.get('authToken') ? 'move' : 'default'}}
                    draggable={Boolean(authToken) && authToken === queryParams.get('authToken')} 
                    onDoubleClick={() => {openEdit(ele, i, details?.projects)}}
                    onDragEnd={() => {
                        dispatch(editPortfilio(`sections[2].projects`, shiftElementsByPosition(details?.projects, i + 1, dragState + 1), queryParams.get('authToken'), null, 'array'));
                    }}
                    onDragOver={() => setDragState(i)}
                >
                    <Card25 content={ele} />
                </Box>
            ))}
        </Box>
    )
}

export default Project6;