import About3 from "./About3"

const About = ({aboutDetails, aboutType}) => {

    switch(aboutType){
        case 'ABOUT3': return(<About3 aboutDetails={aboutDetails}/>)
        default: return;
    }

}

export default About;