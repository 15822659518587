import Contact1 from "./Contact1";
import Contact2 from "./Contact2";
import Contact4 from "./Contact4";
import Contact5 from "./Contact5";
import Contact6 from "./Contact6";

const Contact = ({contactDetails}) => {
    switch(contactDetails?.selector){
        case 'CONTACT1': return(<Contact1 details={contactDetails}/>)
        case 'CONTACT2': return(<Contact2 details={contactDetails}/>)
        case 'CONTACT4': return(<Contact4 details={contactDetails}/>)
        case 'CONTACT5': return(<Contact5 details={contactDetails}/>)
        case 'CONTACT6': return(<Contact6 details={contactDetails}/>)
        default: return;
    }
}

export default Contact;