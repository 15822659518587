import { Grid, Hidden } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './App.css';
import Template from './components/Templates';
import { pathNameSelector, setPathName } from './services/PathSlice';
import { setFontFamily, setTemplate, setTheme, templateSelector, themeSelector } from './services/ThemeSlice';
import { fontTemplates, themeTemplates } from './style/theme';
// import templateDetails from './jsonEntries/template6.json';
// import contactDetails from './jsonEntries/contact6.json';
// import navDetails from './jsonEntries/navItems1.json';
import Navigation from './components/Navigation/index';
import Contact from './components/Contact';
// import templateDetails from '../src/jsonEntries/template2.json';
// import contactDetails from '../src/jsonEntries/contact2.json'
import { contactDetailsSelector, createSession, getTemplateDetails, navDetailsSelector, resourcesSelector, showErrorTemplateSelector, syncAnalytics, templateDetailsSelector, templateLoadedSelector, titleSelector } from './services/TemplateSlice';
import useScrollPercentage from './utils/useScrollPercentage';
import TextEditing from './editingComponents/TextEditing';
import Notifications from './components/Notifications';
import ErrorPage from './errorComponents/ErrorPage';

export const App = () => {
  const dispatch = useDispatch();
  // states which will be changed based on 
  // the total amount of scroll on the screen
  const [scrollRef, scrollPercentage] = useScrollPercentage();
  const location = useLocation();
  const references = useRef([]);

  const theme = useSelector(themeSelector)
  const template = useSelector(templateSelector)
  const templateDetails = useSelector(templateDetailsSelector);
  const contactDetails = useSelector(contactDetailsSelector);
  const navDetails = useSelector(navDetailsSelector);
  const resources = useSelector(resourcesSelector);
  const title = useSelector(titleSelector);
  const pathName = useSelector(pathNameSelector);
  const showErrorTemplate = useSelector(showErrorTemplateSelector);
  const templateLoaded = useSelector(templateLoadedSelector);

  useEffect(() => {
    // Add event listeners when the component mounts
    window.addEventListener('dragover', handleWindowDragOver);
    window.addEventListener('drop', handleWindowDrop);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('dragover', handleWindowDragOver);
      window.removeEventListener('drop', handleWindowDrop);
    };
  }, []);

  /* 
  * Check the scroll percentage changes and set the appropriate
  * scroll percentage in the local storage.
  * The code checks the scroll percentage and changes the scroll
  * percenetage only if it is greater than the current scroll,
  * this way we'll know exctaly till what point the page was ever
  * scrolled durign the session.
  */
  useEffect(() => {
    let scrollDet = JSON.parse(localStorage.getItem("scrollDet"));
    if(scrollDet === null){
        scrollDet = {};
        scrollDet[window.location.pathname] = scrollPercentage;
    }else {
        if(scrollDet[window.location.pathname] === undefined){
            scrollDet[window.location.pathname] = scrollPercentage;
        } else {
            if(scrollDet[window.location.pathname] < scrollPercentage){
                scrollDet[window.location.pathname] = scrollPercentage;
            }
        }
    }
    localStorage.setItem("scrollDet", JSON.stringify(scrollDet));
}, [scrollPercentage]);

  /*
  * Called only once to set up a interval which runs every second to
  * find if the mouse has moved in the last 20 seconds. This way the
  * users will be able to see the metrics with a max error of 20
  * seconds. The counter will stop changing the locally stored data
  * if there is no movement on the website for more than 20 seconds
  */
  useEffect(() => {
    setInterval(() => {
      let lastMoveTime = parseInt(localStorage.getItem('lastMove')) || 0;
      if(new Date().getTime() < (20*1000) + lastMoveTime){
        var timeOnScreen = parseInt(localStorage.getItem('timeOnApp')) || 0;
        timeOnScreen += 1;
        var timeSpent = JSON.parse(localStorage.getItem("timeSpentOnPage")) || {};
        localStorage.setItem("timeOnApp", timeOnScreen);
        var pastChangeScreen = window.location.pathname;
        if(pastChangeScreen !== null){
          if(timeSpent[pastChangeScreen] === undefined){
            timeSpent[pastChangeScreen] = 1;
          } else {
            timeSpent[pastChangeScreen] += 1;
          }
        }
        localStorage.setItem("timeSpentOnPage", JSON.stringify(timeSpent));
      }
    }, 1000);
  }, [])

  /* 
   * Called before the page is closed to send all the
   * locally stored data with the DB.
   */
  window.addEventListener('beforeunload', function (e) {
    if(this.localStorage.getItem("key") !== null){
      dispatch(syncAnalytics());
      this.localStorage.clear();
    }
  });

  /*
  * Listners to check if there was any kind of movement
  * with the mouse(for PC) or touch(for Mobile)
  */
  window.addEventListener('blur', (event) => {
    localStorage.setItem("lastMove", new Date().getTime() - (20*1000));
  })

  window.addEventListener("focus", (event) => {
    localStorage.setItem("lastMove", new Date().getTime());
  })

  document.addEventListener("visibilitychange", (event) => {
    if(document.visibilityState === 'visible'){
      localStorage.setItem("lastMove", new Date().getTime());
    }else{
      localStorage.setItem("lastMove", new Date().getTime() - (20*1000));
    }
  })

  document.addEventListener("mouseleave", (event) => {
    localStorage.setItem("lastMove", new Date().getTime() - (20*1000));
  })

  document.addEventListener("mousemove", (event) => {
    localStorage.setItem("lastMove", new Date().getTime());
  })

  document.addEventListener("touchstart", (event) => {
    localStorage.setItem("lastMove", new Date().getTime());
  })
  document.addEventListener("touchend", (event) => {
    localStorage.setItem("lastMove", new Date().getTime());
  })

  /*
  * Called on the change of the pathname, this will be used
  * to track the path the user folloed during the session.
  * This shows the users which page has been viewed the most
  */
  useEffect(() => {
    let prevPath = localStorage.getItem("userPath");
    if(prevPath === null){
      localStorage.setItem("userPath", window.location.pathname + ", ")
    }else {
      let addPath = prevPath.split(", ").filter(path => path !== '');
      if(window.location.pathname !== addPath[addPath.length - 1]){
        addPath.push(window.location.pathname);
        localStorage.setItem("userPath", addPath.filter(path => path !== '').join(", "))
      }
    }
  // eslint-disable-next-line
  }, [window.location.pathname])

  /*
  * Called once initially to get the template details and
  * checks if there is an active session and creates a new
  * session, this will be used to calc the total visits.
  */
  useEffect(() => {
    dispatch(getTemplateDetails())
    if(localStorage.getItem("key") === null){
      dispatch(createSession())
    }
  }, [dispatch])
  
  /**
   * Changes the title based on the data from the backend
   * Selects a template based on the data received from
   * the backend, this will basically select the theme
   * of the website.
   */
  useEffect(()=> {
    document.title = title;
    if(resources['PROFILE_IMAGE'] !== undefined){
      const favicon = document.querySelector("link[rel='icon']");
      favicon.href = resources['PROFILE_IMAGE'];
    }

    switch(templateDetails?.mainSelector){
      case 'BUSINESS': dispatch(setTemplate('template3'));break;
      case 'RESUME1': dispatch(setTemplate('template1'));break;
      case 'RESUME2': dispatch(setTemplate('template2'));break;
      case 'RESUME3': dispatch(setTemplate('template4'));break;
      case 'RESUME4': dispatch(setTemplate('template5'));break;
      case 'RESUME5': dispatch(setTemplate('template6'));break;
      default: dispatch(setTemplate('template1'));
    }
  }, [dispatch, templateDetails, title, resources])

  useEffect(()=>{
    dispatch(setTheme({
      light: themeTemplates[template]
    }))
    dispatch(setFontFamily(fontTemplates[template]))
  },[template, dispatch])

  /**
   * Whenever there is a change in the path the scroll
   * would not change (lol), so just scrolling it back
   * to the start of the screen whenever there is a 
   * path change. Also change the path name so that the
   * selectors can render the right screen based on the 
   * path.
   */
  useEffect(()=>{
    dispatch(setPathName(location.pathname))
    window.scrollTo(0, 0);
  },[location, dispatch])

  const handleWindowDragOver = (event) => {
    event.preventDefault();
    const y = event.clientY;
    if (y < 100) {
      window.scrollBy(0, -10);
    } else if (y > window.innerHeight - 100) {
      window.scrollBy(0, 10);
    }
  };

  const handleWindowDrop = (event) => {
    event.preventDefault();
  };

  /**
   * Logic to render the right screens.
   * Check for template details, if there is an error
   * sent from the backend (404), render the error page
   * Check for a different key to render the error screen
   * if not during the load it would show the error
   */
  return (
    <Hidden xlDown={!templateLoaded}>
      <Box ref={scrollRef} style={{ position: 'relative', height: "100vh", overflowY: "scroll" }}>
        <TextEditing />
        <Notifications />
        {(template && templateDetails && !showErrorTemplate && theme?.light) ?
            <>
              <Navigation navDetails={navDetails} refs={references} navStyles={{background: theme.light[templateDetails?.background], color: theme.light[templateDetails?.color]}}/>
              <Template templateDetails={templateDetails} screen={navDetails?.navItems?.filter(item => item.ref === pathName)[0]} refs={references}/>
              {Object.keys(contactDetails).length > 0 ? 
                <Grid container style={{padding: 0, textAlign: 'center'}} ref={ref => references.current[templateDetails.sections.length] = ref}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Contact contactDetails={contactDetails}/>
                  </Grid>
                </Grid>: 
                <Grid container style={{padding: 0, textAlign: 'center'}}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Contact contactDetails={contactDetails}/>
                  </Grid>
                </Grid>
              }
            </>
        : <ErrorPage />}
      </Box>
    </Hidden>
  )
}

export default App;
