import Home1 from "./Home1"

const Home = ({homeDetails, homeType}) => {

    switch(homeType){
        case 'HOME1': return(<Home1 homeDetails={homeDetails}/>)
        default: return(<Home1 homeDetails={homeDetails}/>)
    }

}

export default Home;