import { Box, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice"
import { getTextStyles } from "../../style/textStyles"

const Details11 = ({details}) => {
    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)

    const styles = {
        wrap: {
            padding: '10vw',
            boxShadow: `rgba(17, 12, 46, 0.15) 0px 48px 100px 0px`
        },
        header: {
            background: theme.light.primary,
            padding: '2vw'
        },
        imageWrap: {
            background: theme.light.default,
            display: 'flex',
            justifyContent: 'center'
        },
        image: {
            width: '500px',
            height: '400px',
            objectFit: 'scale-down',
            objectPosition: 'center'
        },
        content: {
            background: theme.light.default,
            padding: '3vw',
            textAlign: 'center'
        },
        footer: {
            background: theme.light.info,
            padding: '2vw'
        }
    }

    return(
        <Box style={styles.wrap}>
            <Box style={styles.header}>
                <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{details?.name}</Typography>
            </Box>
            <Box>
                <Box style={styles.imageWrap}>
                    <img src={details?.image} alt="product-details" style={styles.image}/>
                </Box>
                <Box style={styles.content}>
                    <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{details?.description}</Typography>
                </Box>
            </Box>
            <Box style={styles.footer}>
                <Typography variant="h6" style={{...getTextStyles(template, 'header6', {}), ...{color: theme.light.default, fontFamily: fontFamily.primary, opacity: 1}}}>{details?.technologies}</Typography>
            </Box>
        </Box>
    )
}

export default Details11