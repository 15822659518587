import { Box, Grid, IconButton, Typography } from "@mui/material";
import Card20 from "../Card/Card20";
import { getTextStyles } from "../../style/textStyles";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { useLocation } from "react-router-dom";
import { authTokenSelector, editPortfilio } from "../../services/TemplateSlice";
import { Add } from "@mui/icons-material";
import { setEditData } from "../../services/EditingSlice";
import { shiftElementsByPosition } from "../../editingComponents/Helpers";
import { useState } from "react";

const Experience5 = ({details}) => {
    const template = useSelector(templateSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const theme = useSelector(themeSelector)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authToken = useSelector(authTokenSelector);
    const [dragState, setDragState] = useState(null);
    const dispatch = useDispatch();

    const openEdit = (ele, i, arrayContent) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: ele,
            editSubType: typeof(i) === 'number' ? '' : 'array',
            arrayContent: arrayContent,
            arrayIndex: i,
            editingFields: [
                {key: 'company', label: 'Company'},
                {key: 'role', label: 'Role Name'},
                {key: 'description', label: 'Description'},
                {key: 'year', label: 'Duration'},
            ],
            editTitle: 'Add Experience',
            path: typeof(i) === 'number' ? `sections[1].experiences[${i}].company,sections[1].experiences[${i}].year,sections[1].experiences[${i}].role,sections[1].experiences[${i}].description` : `sections[1].experiences`,
            deletePath: `sections[1].experiences`,
        }))
    }

    return(
        <Box style={{padding: '2vw'}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{fontFamily: fontFamily.primary, color: theme.light.info, textAlign: 'center', paddingBottom: '3vw'}}}>{details?.title}
                {Boolean(authToken) && authToken === queryParams.get('authToken') && <IconButton style={{marginBottom: 10, color: '#000', marginLeft: 10}} onClick={() => openEdit(undefined, undefined, details?.experiences)}><Add/></IconButton>}
                </Typography>
            </Grid>
            <Grid container spacing={4} style={{display: 'flex', justifyContent: 'center'}}>
                {details?.experiences?.map((ele, i) => (
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{marginBottom: '1vw'}}>
                        <Card20 content={{...ele, index: i}}
                            onDoubleClick={() => {openEdit(ele, i, details?.experiences)}}
                            onDragEnd={() => {
                                dispatch(editPortfilio(`sections[1].experiences`, shiftElementsByPosition(details?.experiences, i + 1, dragState + 1), queryParams.get('authToken'), null, 'array'));
                            }}
                            onDragOver={() => setDragState(i)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default Experience5;