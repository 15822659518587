import Template1 from "./Template1"
import Template2 from "./Template2";
import Template3 from "./Template3";
import Template4 from "./Template4";
import Template5 from "./Template5";
import Template6 from "./Template6";

const Template = ({templateDetails, screen, refs}) => {
    switch(templateDetails?.mainSelector){
        case 'RESUME1': return (<Template1 templateDetails={templateDetails} screen={screen} refs={refs}/>)
        case 'RESUME2': return (<Template2 templateDetails={templateDetails} screen={screen} refs={refs}/>)
        case 'RESUME3': return (<Template4 templateDetails={templateDetails} screen={screen} refs={refs}/>)
        case 'RESUME4': return (<Template5 templateDetails={templateDetails} screen={screen} refs={refs}/>)
        case 'RESUME5': return (<Template6 templateDetails={templateDetails} screen={screen} refs={refs}/>)
        case 'BUSINESS': return (<Template3 templateDetails={templateDetails} screen={screen} refs={refs}/>)
        default: return;
    }

}

export default Template;