import { AppBar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, Toolbar, Typography } from "@mui/material";
import { MenuOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PropTypes } from 'prop-types';
import { fontFamilySelector, themeSelector } from "../../services/ThemeSlice";

const Navigation3 = ({
    sections,
    navDetails
}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    
    const styles = {
        appbar:{
            boxShadow: 'none',
            backgroundColor: theme.light.default,
        },
        link:{
            color: theme.light.tertiary,
            fontFamily: fontFamily.secondary,
            textTransform: 'capitalize',
            fontSize: `calc(2px + 1vw)`,
            fontWeight: 'lighter',
            margin: '0 0.8vw'
        },
        header:{
            padding: '10px'
        },
        drawer:{
            width: '35vw',
            textAlign: 'center'
        },
        mobileLink:{
            color: theme.light.tertiary,
            fontFamily: fontFamily.secondary,
            padding: '10px 0'
        }
    }

    const [mobileOpen, setMobileOpen] = useState(false);

    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setMobileOpen((prevState) => !prevState)
    }

    const handleNavigation = (ref, index) => {
        navigate(ref)
    }

    return (
        <Box>
            <AppBar style={styles.appbar}>
                <Toolbar style={styles.toolbar}>
                    <IconButton 
                        edge = "start" 
                        onClick = {handleDrawerOpen}
                        sx = {{display: {xs:'block', sm: 'block', md: 'none'}}}
                    >
                        <MenuOutlined/>
                    </IconButton>
                    <Box variant="h6" sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                        <img alt="header-icon" src={navDetails?.mainContent} width="40%" height="auto"/>
                    </Box>
                    <Typography variant="h6" sx={{flexGrow: 1, display: {xs: 'none', sm: 'none', md: 'block'}}}></Typography>
                    <Box sx={{display: { xs: 'none', sm: 'none', md: 'block'}}}>
                        {navDetails?.navItems?.map((item, i) => (
                            item.name ?
                            <Button key={i} style={styles.link} onClick={()=>handleNavigation(item.ref, i)}>{item.name}</Button> : ''
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                open = {mobileOpen}
                onClose = {handleDrawerOpen}
                sx = {{display: { xs: 'block', sm: 'block', md: 'none' }}}
            >
                <Box onClick={handleDrawerOpen} style={styles.drawer}>
                    <Box style={styles.header}>
                        <img alt="header-icon" src={navDetails?.mainContent} width="20%" height="auto"/>
                    </Box>
                    <Divider></Divider>
                    <List>
                        {navDetails?.navItems?.map((item, i) => (
                            <ListItem key={i} disablePadding={true} style={styles.mobileLink}>
                                <ListItemButton style={{justifyContent: 'center'}} onClick={()=>handleNavigation(item.ref, i)}>
                                    {item.name}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
    )
}

Navigation3.propTypes = {
    sections: PropTypes.object,
    navDetails: PropTypes.object.isRequired,
}

export default Navigation3;