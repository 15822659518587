import { TextField } from "@mui/material"

const CustomField = ({fieldDetails, value, inputChangeHandler}) => {
    const styles = {
        textField : {
            width: '100%'
        }
    }

    switch(fieldDetails.selector){
        case 'TEXTFIELD' : 
            return(<TextField 
                {...fieldDetails}
                value={value? value : ''}
                onChange={(event) => inputChangeHandler(event)}
                error={fieldDetails.required? (value === '' ? true : (fieldDetails.regex && value? (value.match(fieldDetails.regex)? false : true ): false)) : (fieldDetails.regex && value? (value.match(fieldDetails.regex)? false : true ): false)}
                style={styles.textField}
            />)
        default: 
            return;
    }
}

export default CustomField;