import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { getTextStyles } from "../../style/textStyles";
import { resourcesSelector } from "../../services/TemplateSlice";
import { setEditData } from "../../services/EditingSlice";


const About4 = ({details}) => {
    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const resources = useSelector(resourcesSelector)

    const dispatch = useDispatch();

    const styles = {
        mainWrap: {
            background: theme.light.default,
            padding: '4vw 7vw'
        },
        outer: {
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            padding: '8px',
            margin: '2vw'
        },
        inner: {
            background: theme.light.default,
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        skills: {
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center'
        },
        aboutWrap: {
            background: theme.light.primary, 
            padding: '3vw 10vw'
        },
        quotation: {
            fontSize: '10vw', 
            color: theme.light.default, 
            fontFamily: fontFamily.primary
        },
        imageWrap: {
            marginTop: window.innerWidth > 900 ? '-300px' : '2vw', 
            display: 'flex', 
            flexDirection: 'column',  
            alignItems: 'center'
        },
        image: {
            width: '300px',
            height: '400px',
            objectFit: 'cover', 
            objectPosition: 'center'
        },
        imageBorder: {
            width: '300px', 
            height: '20px', 
            background: theme.light.default, 
            marginTop: '40px'
        }
    }

    const openEditName = (name) => {
        dispatch(setEditData({
            editType: 'text',
            editContent: name,
            editTitle: 'Edit Name',
            path: `sections[0].about.name`
        }))
    }

    const openEditImage = (img) => {
        dispatch(setEditData({
            editType: 'image',
            editContent: img,
            editTitle: 'Edit Picture',
            path: img
        }))
    }

    const openEditSkill = (details, i) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: details,
            editingFields: [
                {key: 'skill', label: 'Skill Name'},
                {key: 'level', label: 'Level'}
            ],
            editTitle: 'Edit Skill',
            path: `sections[0].skills.content[${i}].skill,sections[0].skills.content[${i}].level`
        }))
    }

    const openEditAbout = (about) => {
        dispatch(setEditData({
            editType: 'text',
            editContent: about,
            regenerateSection: 'ABOUT_ME',
            editTitle: 'Edit About',
            path: `sections[0].about.content`
        }))
    }

    return(
        <Box>
            <Box style={styles.mainWrap}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{fontWeight: '800', fontFamily: fontFamily.primary, textAlign: 'left', fontSize: '8vw'}}} onDoubleClick={() => openEditName(details?.about?.name)}>{details?.about?.name}</Typography>
                        <Box style={{display: 'flex', flexWrap: 'wrap'}}>
                            {details?.skills?.content?.map((ele, i) => (
                                <Box style={styles.skills} key={i} onDoubleClick={() => openEditSkill(ele, i)}>
                                    <Box style={{...styles.outer, ...{background: `conic-gradient(${theme.light.primary} ${ele.level}, ${theme.light.tertiary} calc(100% - ${ele.level}))`}}}>
                                        <Box style={styles.inner}> 
                                            <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele.level}</Typography> 
                                        </Box>
                                    </Box>
                                    <Typography variant="h6" style={{...getTextStyles(template, 'header6', {}), ...{fontFamily: fontFamily.primary, fontWeight: '700'}}}>{ele.skill}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box style={styles.aboutWrap}>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={4} lg={4} sx={{display: {xs: 'block', sm: 'block', md: 'none', lg: 'none'}}}>
                        <Box style={styles.imageWrap} onDoubleClick={() => openEditImage(details?.about?.image)}>
                            <img src={resources[details?.about?.image]} style={styles.image} alt="about"/>
                            <Box style={styles.imageBorder}></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Typography variant="h1"><FontAwesomeIcon icon={faQuoteLeft} style={styles.quotation}/></Typography>
                        <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, textAlign: 'left'}}} onDoubleClick={() => openEditAbout(details?.about?.content)}>
                            {details?.about?.content}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} sx={{display: {xs: 'none', sm: 'none', md: 'block', lg: 'block'}}}>
                        <Box style={styles.imageWrap} onDoubleClick={() => openEditImage(details?.about?.image)}>
                            <img src={resources[details?.about?.image]} style={styles.image} alt="about"/>
                            <Box style={styles.imageBorder}></Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default About4;