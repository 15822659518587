import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Typography } from "@mui/material";
import Card2 from "../Card/Card2";
import { getTextStyles } from "../../style/textStyles";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { useState } from "react";
import { Add, ExpandMore } from "@mui/icons-material";
import { setEditData } from "../../services/EditingSlice";
import { useLocation } from "react-router-dom";
import { authTokenSelector, editPortfilio } from "../../services/TemplateSlice";
import { shiftElementsByPosition } from "../../editingComponents/Helpers";

const Experience1 = ({details}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authToken = useSelector(authTokenSelector);
    const [dragState, setDragState] = useState(null);
    
    const dispatch = useDispatch();

    const styles = {
        wrap: {
            display: 'flex'
        },
        title: {
            background: `linear-gradient(60deg, ${theme.light.secondary}, ${theme.light.tertiary})`,
            padding: '1vw',
            cursor: 'pointer',
            margin: '1vw 0',
            borderRadius: '12px'
        },
    }

    const [index, setIndex] = useState(0)
    const [display, setDisplay] = useState(true)

    const handleClick = (index) => {
        setDisplay(false)
        setTimeout(() => {
            setDisplay(true)
            setIndex(index)
        },100)
    }

    const openEditExperience = (experience, i, arrayContent) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: experience,
            editSubType: typeof(i) === 'number' ? '' : 'array',
            arrayContent: arrayContent,
            arrayIndex: i,
            editingFields: [
                {key: 'company', label: 'Company'},
                {key: 'role', label: 'Role'},
                {key: 'year', label: 'Year'},
                {key: 'description', label: 'Description'}
            ],
            editTitle: 'Edit Experience',
            path: `sections[1].experiences[${i}].company,sections[1].experiences[${i}].role,sections[1].experiences[${i}].year,sections[1].experiences[${i}].description`,
            deletePath: `sections[1].experiences`,
        }))
    }

    return(
        <>
            {details && <Grid container style={styles.wrap}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{padding: '3vw 0'}}>
                    <Typography variant="h1" style={getTextStyles(template, "header1", {color: theme.light.secondary, fontFamily: fontFamily.primary, paddingBottom: 0})}>{details?.header}
                    {authToken === queryParams.get('authToken') && <IconButton style={{marginBottom: 10, color: '#000', marginLeft: 10}} onClick={() => openEditExperience(undefined, undefined, details?.experiences)}><Add/></IconButton>}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} sx={{display: {xs: 'none', sm: 'none', md: 'block', lg: 'block'}, maxHeight:'500px', overflow: 'auto'}}>
                    {details?.experiences?.map((ele, i)=>(
                        <Box 
                            key={i} 
                            style={{...styles.title, cursor: Boolean(authToken) && authToken === queryParams.get('authToken') ? 'move' : 'default'}} 
                            boxShadow={8} 
                            onClick={() => handleClick(i)} 
                            onDoubleClick={() => openEditExperience(ele, i, details?.experiences)}
                            draggable={Boolean(authToken) && authToken === queryParams.get('authToken')} 
                            onDragEnd={(event) => {
                                dispatch(editPortfilio(`sections[1].experiences`, shiftElementsByPosition(details?.experiences, i + 1, dragState + 1), queryParams.get('authToken'), null, 'array'));
                            }} 
                            onDragOver={() => setDragState(i)}>
                            <Typography variant="h4" style={getTextStyles(template, 'header4', {color: theme.light.default, fontFamily: fontFamily.primary})}>{ele.company}</Typography>
                            <Typography variant="h5" style={getTextStyles(template, 'header5', {color: theme.light.default, fontFamily: fontFamily.primary})}>{ele.role}</Typography>
                            <Typography variant="h6" style={getTextStyles(template, 'header6', {color: theme.light.default, fontFamily: fontFamily.primary})}>{ele.year}</Typography>
                        </Box>
                    ))}
                </Grid> 
                {display ? <Grid item xs={12} sm={12} md={8} lg={8} sx={{display: {xs: 'none', sm: 'none', md: 'flex', lg: 'flex'}, justifyContent: 'center', paddingBottom: '50px'}}>
                    <Card2 content={details?.experiences?.[index]} doubleClick={() => openEditExperience(details?.experiences?.[index], index, details?.experiences)}/>
                </Grid> : ''}
                
                {details && <Grid item xs={12} sm={12} sx={{display: {md: 'none', lg: 'none'}}}>
                    {details?.experiences?.map((experience, i) => (
                        <Accordion key={i} onDoubleClick={() => openEditExperience(experience, i, details?.experiences)}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h4" style={{...getTextStyles(template, 'header4', {color: theme.light.secondary, fontFamily: fontFamily.primary}), ...{width: '33%', flexShrink: 0}}}>{experience?.company}</Typography>
                                <Typography variant="h5" style={{...getTextStyles(template, 'header5', {color: theme.light.secondary, fontFamily: fontFamily.primary}), ...{width: '50%', flexShrink: 0}}}>{experience?.role}</Typography>
                                <Typography variant="h6" style={getTextStyles(template, 'header6', {color: theme.light.secondary, fontFamily: fontFamily.primary})}>{experience?.year}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {experience?.description?.split('\n').map((ele, i) => (
                                    <Typography key={i} variant="body" style={{...getTextStyles(template, 'body', {color: theme.light.primary, fontFamily: fontFamily.primary}), ...{textAlign: 'left', padding:'0.5vmin 0'}}}>{ele}</Typography>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>}
            </Grid>}
        </>
    )
}

export default Experience1;