import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import PropTypes from 'prop-types'
import { setEditData } from "../../services/EditingSlice";
import { useLocation } from "react-router-dom";
import { authTokenSelector, editPortfilio } from "../../services/TemplateSlice";
import { shiftElementsByPosition } from "../../editingComponents/Helpers";
import { useState } from "react";

const Card16 = ({content}) => {
    const styles = {
        dot: {
            border: '2px solid lightgrey',
            width: '10px',
            height: '10px',
            padding: '10px',
            borderRadius: '50%',
        },
        line: {
            width: '2px',
            minHeight: '40px',
            height: '90%',
            background: 'lightgrey',
            marginLeft: '15px',
            marginTop: '5px'
        }
    }

    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authToken = useSelector(authTokenSelector);
    const [dragState, setDragState] = useState(null);

    const dispatch = useDispatch()

    const openEditExperience = (ele, i, arrayContent) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: ele,
            editSubType: typeof(i) === 'number' ? '' : 'array',
            arrayContent: arrayContent,
            arrayIndex: i,
            editingFields: [
                {key: 'company', label: 'Company'},
                {key: 'role', label: 'Role Name'},
                {key: 'description', label: 'Description'},
                {key: 'year', label: 'Duration'},
            ],
            editTitle: 'Edit Experience',
            path: typeof(i) === 'number' ? `sections[1].experiences[${i}].company,sections[1].experiences[${i}].year,sections[1].experiences[${i}].role,sections[1].experiences[${i}].description` : `sections[1].experiences`,
            deletePath: `sections[1].experiences`,
        }))
    }

    return(        
        <Box style={{margin: '2vw'}}>
            {content?.map((ele, i) => (
                <Grid container spacing={4} key={i} style={{marginTop: '2px', cursor: Boolean(authToken) && authToken === queryParams.get('authToken') ? 'move' : 'default'}} 
                    onDoubleClick={() => openEditExperience(ele, i, content)}
                    draggable={Boolean(authToken) && authToken === queryParams.get('authToken')} 
                    onDragEnd={(event) => {
                        dispatch(editPortfilio(`sections[2].experiences`, shiftElementsByPosition(content, i + 1, dragState + 1), queryParams.get('authToken'), null, 'array'));
                    }} 
                    onDragOver={() => setDragState(i)}>
                    <Grid item md={2} lg={2} sx={{display: {xs: 'none', sm: 'none', md: 'block', lg:'block'}}}>
                        <Box style={{marginTop: '8px'}}><Typography variant="h5" style={{...getTextStyles(template, 'header5', {}), ...{color: theme.light.secondary, fontWeight:'800', fontFamily: fontFamily.primary}}}>{ele.year}</Typography></Box> 
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} lg={1}>
                        <Box style={styles.dot}></Box>
                        <Box style={styles.line}></Box>
                    </Grid>
                    <Grid item xs={10} sm={11} md={9} lg={9}>
                        <Box style={{width: '100%', marginTop: '8px'}}>
                            <Typography variant="h5" sx={{...getTextStyles(template, 'header5', {}), ...{color: theme.light.secondary, fontFamily: fontFamily.primary, display: {xs: 'block', sm:'block', md: 'none', lg: 'none'}}}}>{ele.year}</Typography>
                            <Typography variant="h5" style={{...getTextStyles(template, 'header5', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele.company}</Typography>
                            <Typography variant="h6" style={{...getTextStyles(template, 'header6', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele.role}</Typography>
                            <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele.description}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            ))}
        </Box>
    )
}

Card16.propTypes = {
    content: PropTypes.array.isRequired
}

export default Card16;