import { combineReducers } from "@reduxjs/toolkit";
import pathReducer from '../services/PathSlice';
import themeReducer from '../services/ThemeSlice';
import templateReducer from "../services/TemplateSlice";
import editingReducer from "../services/EditingSlice";
import notificationReducer from "../services/NotificationSlice";

const appReducer = combineReducers({
    pathHandler: pathReducer,
    themeHandler: themeReducer,
    templateHandler: templateReducer,
    editingHandler: editingReducer,
    notificationHandler: notificationReducer,
})

export const rootReducer = (state, action) => {
    return appReducer(state, action)
}