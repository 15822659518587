import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { themeSelector } from '../../services/ThemeSlice';
import Card1 from '../Card/Card1';

const About1 = ({details, refs}) => {
    const theme = useSelector(themeSelector)
    
    const styles = {
        cover: {
            position: 'relative',
            width: '100%',
            height: '100vh',
            overflow: 'hidden',
            background: theme.light.default,
            zIndex: -1
        },
        left: {
            position: 'absolute',
            borderRadius: '100%',
            width: '150vmin',
            height: '100vmin',
            top: window.innerWidth > 700 ? '30vmin' : '60vh',
            left: '-40vmin',
            bottom: 0,
            transform: 'skewY(25deg)',
            background: `linear-gradient(to top, ${theme.light.secondary}, ${theme.light.tertiary})`,
            zIndex: 1
        },
        right: {
            position: 'absolute',
            borderRadius: '100%',
            width: '50vmin',
            height: '30vmin',
            top: 0,
            right: '-20vmin',
            bottom: 0,
            transform: 'skewY(20deg)',
            background: `linear-gradient(to top, ${theme.light.secondary}, ${theme.light.tertiary})`,
            zIndex: 1
        },
        tile: {
            position: 'absolute',
            zIndex: 10,
            margin: '0 12%',
            top: window.innerWidth > 900 ? '30%' : '20%',
            bottom: '20%'        
        }
    }

    return (
        <>
            <Box style={styles.cover}>
                <Box className='left' style={styles.left}></Box>
                <Box className='right' style={styles.right}></Box>
            </Box>
            <Box style={styles.tile}>
                <Card1 details={details} refs={refs}/>
            </Box>
        </>
    )
}

export default About1;