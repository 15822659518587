import { Button, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { LinkedIn } from "@mui/icons-material";
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { getButtonStyles } from "../../style/buttonStyles";
import logo from '../../assets/Template2/logo.webp'

const Contact2 = () => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const styles = {
        container:{
            margin: window.innerWidth > 900 ? '5vw 5vw 0 5vw' : 0,
        },
        leftBox:{
            background: theme.light.primary,
            textAlign: 'left',
            padding: '8vw 8vw 5vw 8vw'
        },
        textContainer: {
            paddingTop: '3vw',
            fontWeight: 'bold'
        },
        info: {
            paddingTop: '3vw',
            color: theme.light.default
        },
        divider: {
            paddingTop: '0.8vw',
            borderColor: theme.light.default,
            borderWeight: '4px'
        },
        button:{
            paddingTop: '2vw'
        },
        btnProps: {
            background: theme.light.default,
            color: theme.light.primary
        },
        phone:{
            lineHeight: 0, 
            textAlign: 'right', 
            textDecoration: 'underline', 
            textDecorationColor: theme.light.default
        },
        rightBox:{
            background: theme.light.secondary,
            padding: '10vw 3vw'
        },
        link: {
            fontFamily: fontFamily.primary,
            color: theme.light.info,
            textTransform: 'capitalize',
            fontSize: `calc(2px + 1vw)`
        },
        credits: {
            background: theme.light.defaault,
            textAlign: 'center',
            padding: '2vw 0'
        },
        captionText: {
            color: theme.light.info,
            fontSize: `calc(7px + 0.5vw)`,
            fontFamily: fontFamily.secondary
        }
    }

    return(
        <Box style={styles.container}>
            <Grid container>
                <Grid item xs={12} sm={12} md={8} lg={8} style={styles.leftBox}>
                    <Typography style={{...getTextStyles(template, 'header2', {fontFamily: fontFamily.primary, color: theme.light.default}), ...{textTransform: 'uppercase', textAlign: 'left'}}}>Contact Us</Typography>

                    <Grid container style={styles.textContainer}>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Typography style={{...getTextStyles(template, 'header5', {fontFamily: fontFamily.secondary, color: theme.light.default}), ...{fontWeight: 'bold'}}}>M5 Info Solutions Private Limited</Typography>
                            <Box style={styles.info}>
                                <Typography style={{...getTextStyles(template, 'body', {fontFamily: fontFamily.secondary}), ...{lineHeight: 0}}}>Delivery Centre - Bangalore - Mysore</Typography>
                                <Typography style={{...getTextStyles(template, 'body', {fontFamily: fontFamily.secondary}), ...{lineHeight: 0}}}>Collection Centre - Malur - Pandavapura -Perisandra</Typography>
                                <Typography style={{...getTextStyles(template, 'body', {fontFamily: fontFamily.secondary}), ...{lineHeight: 0}}}>Kisan Paripoorna Kendra - Malur - Pandavapura - Perisandra</Typography>
                            </Box>
                            <Divider style={styles.divider}/>
                            <Typography style={{...getTextStyles(template, 'header3', {fontFamily: fontFamily.primary, color: theme.light.default}), ...{paddingTop:'1vw', textAlign: 'left'}}}>info@m5 infosolutions.com</Typography>
                            <Box style={styles.button}>
                                <Button style={getButtonStyles('floating', styles.btnProps)}><FacebookIcon style={{fontSize: `3vmin`}}></FacebookIcon></Button>
                                <Button style={getButtonStyles('floating', styles.btnProps)}><YouTubeIcon style={{fontSize: `3vmin`}}></YouTubeIcon></Button>
                                <Button style={getButtonStyles('floating', styles.btnProps)}><LinkedIn style={{fontSize: `3vmin`}}></LinkedIn></Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Typography style={getTextStyles(template, 'body', {fontFamily: fontFamily.secondary, color: theme.light.default})}>54/3, 2nd Cross 1st R Block Rajajinagar Bangalore- 560010</Typography>
                            <Box>
                                <Typography style={{...getTextStyles(template, 'body', {fontFamily: fontFamily.secondary, color: theme.light.default}), ...styles.phone}}>Phone: 080-23577966</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} style={styles.rightBox}>
                    <Typography style={{...getTextStyles(template, 'header3', {fontFamily: fontFamily.primary, color: theme.light.primary}), ...{textTransform: 'uppercase'}}}>Be the first to know</Typography>
                    <Typography style={{...getTextStyles(template, 'header5', {fontFamily: fontFamily.primary, color: theme.light.info}), ...{padding: '3vw'}}}>Sign up to our newsletter to stay informed</Typography>
                    <Button style={styles.link}>Subsribe Now</Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={styles.credits}>
                    <img alt={'logo'} src={logo} width="8%" />
                    <Typography style={styles.captionText}>© 2023 Bhoomee. Powered and secured by M5 Info Solutions Pvt Ltd</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Contact2;