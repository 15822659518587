import { AppBar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, Toolbar, Typography } from "@mui/material";
import { MenuOutlined } from "@mui/icons-material";
import { useState } from "react";
import { getTextStyles } from "../../style/textStyles";
import { useDispatch, useSelector } from "react-redux";
import { PropTypes } from 'prop-types';
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { setEditData } from "../../services/EditingSlice";

const Navigation1 = ({
    sections,
    navDetails,
    navStyles
}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    
    const styles = {
        appbar:{
            boxShadow: 'none',
            backgroundColor: navStyles?.background,
        },
        link:{
            color: navStyles?.color,
            fontFamily: fontFamily.primary,
            textTransform: 'capitalize',
            fontSize: `calc(2px + 1vw)`,
            fontWeight: 'lighter',
            margin: '0 0.8vw'
        },
        header:{
            padding: '10px'
        },
        drawer:{
            width: '35vw',
            textAlign: 'center'
        },
        mobileLink:{
            color: navStyles?.mobileColor,
            fontFamily: fontFamily.primary,
            padding: '10px 0'
        }
    }

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerOpen = () => {
        setMobileOpen((prevState) => !prevState)
    }

    const handleNavigation = (ref, index) => {
        if(location.pathname !== '/'){
            navigate('/')
            setTimeout(() => {
                sections.current[index].scrollIntoView({behavior: 'smooth'})
            },100)
        }else{
            sections.current[index].scrollIntoView({behavior: 'smooth'})
        }
    }

    const openEditTitle = (title) => {
        dispatch(setEditData({
            editType: 'text',
            editContent: title,
            editTitle: 'Edit Title',
            path: 'navDetails.mainContent'
        }))
    }

    return (
        <Box>
            <AppBar style={styles.appbar}>
                <Toolbar style={styles.toolbar}>
                    <IconButton 
                        edge = "start" 
                        onClick = {handleDrawerOpen}
                        sx = {{display: {xs:'block', sm: 'block', md: 'none'}}}
                    >
                        <MenuOutlined style={{color: 'grey'}}/>
                    </IconButton>
                    <Box variant="h6" sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                        <Typography variant="h2" style={getTextStyles(template, 'header2', {color: theme.light.secondary, fontFamily: fontFamily.primary})} onDoubleClick={() => openEditTitle(navDetails?.mainContent)}>{navDetails?.mainContent}</Typography>
                    </Box>
                    <Typography variant="h6" sx={{flexGrow: 1, display: {xs: 'none', sm: 'none', md: 'block'}}}></Typography>
                    <Box sx={{display: { xs: 'none', sm: 'none', md: 'block'}}}>
                        {navDetails?.navItems?.map((item, i) => (
                            item.name ?
                            <Button key={i} style={styles.link} onClick={()=>handleNavigation(item.ref, i)}>{item.name}</Button> : ''
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                open = {mobileOpen}
                onClose = {handleDrawerOpen}
                sx = {{display: { xs: 'block', sm: 'block', md: 'none' }}}
            >
                <Box onClick={handleDrawerOpen} style={styles.drawer}>
                    <Box style={styles.header}>
                        <Typography variant="h2" style={getTextStyles(template, 'header2', {color: theme.light.secondary, fontFamily: fontFamily.primary})}>{navDetails?.mainContent}</Typography>
                    </Box>
                    <Divider></Divider>
                    <List>
                        {navDetails?.navItems?.map((item, i) => (
                            <ListItem key={i} disablePadding={true} style={styles.mobileLink}>
                                <ListItemButton style={{justifyContent: 'center'}} onClick={()=>handleNavigation(item.ref, i)}>
                                    {item.name}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
    )
}

Navigation1.propTypes = {
    sections: PropTypes.object,
    navDetails: PropTypes.object.isRequired,
    navStyles: PropTypes.shape({
        background: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    })
}

export default Navigation1;