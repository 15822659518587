import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import Card8 from "../Card/Card8";
import { Typography } from "@mui/material";

const Details2 = ({details}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const styles = {
        container: {
            background: 'linear-gradient(90deg, rgba(127,126,129,0.3) 11.87%, #F2F2F2 100%)'
        },
        cardWrap: {
            padding: `1vw 10vw`
        }
    }

    return(
        <Box style={styles.container}>
            {details?.title && <Typography style={{...getTextStyles(template, details?.titleStyle === undefined ? 'header1' : details?.titleStyle, {color: theme.light.primary, fontFamily: fontFamily.primary}), ...{paddingTop: '8vw'}}}>{details.title}</Typography>}
            {details?.services && <Box style={styles.cardWrap}>
                {details?.services?.map((service)=> (
                    <Card8 content={service} />
                ))}
            </Box>}
        </Box>
    )
}

export default Details2;