import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { fontFamilySelector, templateSelector, themeSelector } from '../../services/ThemeSlice';
import { getTextStyles } from '../../style/textStyles';
import { useNavigate } from 'react-router-dom';

const Card10 = ({content}) => {
    const theme = useSelector(themeSelector);
    const fontFamily = useSelector(fontFamilySelector);
    const template = useSelector(templateSelector);

    const navigate = useNavigate();

    const styles = {
        container: {
            background: theme.light.default,
            border: `1px solid ${theme.light.secondary}`
        },
        image: {
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
        },
        textWrap:{
            padding: '5vw 3vw',
            textAlign: 'left'
        },
        link: {
            fontFamily: fontFamily.primary,
            color: theme.light.info,
            textTransform: 'capitalize',
            fontSize: `calc(5px + 1vw)`,
            padding: 0
        }
    }

    const clickHanlder = () => {
        navigate(content.link)
    }

    return(
        <Box style={styles.container}>
            <Box>
                <img alt={content.alt} src={content.image} style={styles.image} />
            </Box>
            <Box style={styles.textWrap}>
                <Typography style={{...getTextStyles(template, 'header5', {color:theme.light.info, fontFamily:fontFamily.primary}), ...{paddingBottom: '4vw'}}}>{content.header}</Typography>
                <Typography style={{...getTextStyles(template, 'body', {color:theme.light.info, fontFamily:fontFamily.secondary}), ...{paddingBottom: '4vw', textAlign: 'left'}}}>{content.text}</Typography>
                <Button style={styles.link} onClick={clickHanlder}>Read more</Button>
            </Box>
        </Box>
    )
}

Card10.propTypes = {
    content: PropTypes.shape({
        image: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
    })
}

export default Card10;