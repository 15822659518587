import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fontFamilySelector, templateSelector, themeSelector } from '../../services/ThemeSlice';
import { getButtonStyles } from '../../style/buttonStyles';
import CustomField from './CustomField';
import { getTextStyles } from '../../style/textStyles';

const CustomForm = ({formDetails, btnText}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const styles = {
        container: {
            height: 'auto',
            padding: '5vw',
            border: `5px solid ${theme.light.info}`,
        },
        btnProps: {
            color: theme.light.default,
            background: theme.light.primary,
            fontFamily: fontFamily.primary
        },
        gridItems: {
            display:'flex', 
            justifyContent: 'center'
        }
    }

    const [formEntries, setFormEntries] = useState({})
    const [hover, setHover] = useState(false)

    useEffect(()=>{
        var temp = {}
        for(var ele of formDetails){
            temp[ele.name] = null
        }

        setFormEntries(temp)
    }, [formDetails])

    const fieldChangeHandler = (event) => {
        var name = event.target.name
        var value = event.target.value
        
        setFormEntries({...formEntries, [name]:value})
    }

    const submit = () =>{
        var temp = {}
        var invalidEntries = 0

        for(var key of Object.keys(formEntries)){
            if(formEntries[key] === null){
                temp[key] = ''
                setFormEntries({...formEntries, ...temp})
                invalidEntries ++
            }
        }

        if(invalidEntries === 0){
            //submit
            console.log('success')
        }else{
            //alert or do nothing
            console.log('error')
        }
    }

    return(
        <Box style={styles.container}>
            {formDetails && <Grid container spacing={5} style={styles.gridItems}>
                {typeof(formDetails) === 'object' ? 
                    formDetails.map((ele, index)=> (
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <CustomField key={index} fieldDetails={ele} value={formEntries[ele.name]} inputChangeHandler={fieldChangeHandler}/>            
                        </Grid>
                    )) :
                    <Typography style={getTextStyles(template, 'header3', {color: theme.light.info, fontFamily: fontFamily.primary})}>{formDetails}</Typography>
                }
                <Grid item xs={12} sm={12} md={12} lg={12} style={styles.gridItems}>
                    <Button onClick={submit} style={hover? getButtonStyles('pressHover', {color: theme.light.default,background: 'black', fontFamily: fontFamily.primary, marginTop: '9px'}) 
                    : getButtonStyles('press', styles.btnProps)} onMouseEnter={()=>{setHover(!hover)}} onMouseLeave={()=>{setHover(!hover)}}>{btnText}</Button>
                </Grid>
            </Grid>}
        </Box>
    )
}

export default CustomForm;