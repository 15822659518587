import axios from "axios";

function makeUrlQuery(queryObject){
    let query = null;
    if(queryObject !== null){
        query = '?';
        Object.keys(queryObject).forEach(item => {
            query += `${item}=${queryObject[item]}&`
        })
        return query;
    }else {
        return '';
    }
}

export function get(url, queryObject, successHandler, errorHandler){
    fetch(url+makeUrlQuery(queryObject), {headers: {
        'ngrok-skip-browser-warning': true
    }}).then(result => {
        if(result.ok){
            result.json().then(data => {
                if(typeof(successHandler) === 'function'){
                    successHandler(data);
                }
            }).catch(error => {
                if(typeof(errorHandler) === 'function'){
                    errorHandler(error);
                }   
            })
        } else {
            result.json().then(data => {
                if(typeof(errorHandler) === 'function'){
                    errorHandler(data.message);
                } 
            })
        }
    }).catch(error => {
        if(typeof(errorHandler) === 'function'){
            errorHandler(error);
        }   
    })
} 

export function post(url, queryObject, body, successHandler, errorHandler){
    axios.post(url+makeUrlQuery(queryObject), body, {headers:{
        'ngrok-skip-browser-warning': true,
    }, 
        // httpAgent: new http.Agent({ keepAlive: true }),
    }).then(result => {
        if(typeof(successHandler) === 'function'){
            successHandler(result.data);
        }
    }).catch(error => {
        if(typeof(errorHandler) === 'function'){
            errorHandler(error);
        }   
    })
}