import { Box, IconButton, Typography } from "@mui/material";
import Card21 from "../Card/Card21";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { useLocation } from "react-router-dom";
import { authTokenSelector, editPortfilio } from "../../services/TemplateSlice";
import { useState } from "react";
import { setEditData } from "../../services/EditingSlice";
import { Add } from "@mui/icons-material";
import { shiftElementsByPosition } from "../../editingComponents/Helpers";

const Project5 = ({details}) => {
    const template = useSelector(templateSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const theme = useSelector(themeSelector)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authToken = useSelector(authTokenSelector);
    const [dragState, setDragState] = useState(null);

    const dispatch = useDispatch();

    const openEdit = (details, i, arrayContent) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: details,
            editSubType: typeof(i) === 'number' ? '' : 'array',
            arrayContent: arrayContent,
            arrayIndex: i,
            editingFields: [
                {key: 'name', label: 'Project Name'},
                {key: 'intro', label: 'Description'},
                {key: 'technologies', label: 'Technologies Used'}
            ],
            editTitle: 'Edit Project',
            path: typeof(i) === 'number' ? `sections[2].projects[${i}].name,sections[2].projects[${i}].intro,sections[2].projects[${i}].technologies` : 'sections[2].projects',
            deletePath: `sections[2].projects`,
        }))
    }

    return(
        <Box style={{padding: '2vw 10vw'}}>
            <Box>
                <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{fontFamily: fontFamily.primary, color: theme.light.info, textAlign: 'center', paddingBottom: '8vw'}}}>{details?.title}
                {Boolean(authToken) && authToken === queryParams.get('authToken') && <IconButton style={{marginBottom: 10, color: '#000', marginLeft: 10}} onClick={() => openEdit(undefined, undefined, details?.projects)}><Add/></IconButton>}
                </Typography>
            </Box>
            {details?.projects?.map((ele, i) => (
                <Box key={i}>
                    <Card21 content={ele}
                        draggable={Boolean(authToken) && authToken === queryParams.get('authToken')} 
                        onDoubleClick={() => {openEdit(ele, i, details?.projects)}}
                        onDragEnd={() => {
                            dispatch(editPortfilio(`sections[2].projects`, shiftElementsByPosition(details?.projects, i + 1, dragState + 1), queryParams.get('authToken'), null, 'array'));
                        }}
                        onDragOver={() => setDragState(i)}
                    />
                </Box>
            ))}
        </Box>
    )
}

export default Project5;