import { Box, Button, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Sections from "../Sections";
import { getButtonStyles } from "../../style/buttonStyles";
import { getTextStyles } from "../../style/textStyles";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { useDispatch, useSelector } from "react-redux";
import { Email, GitHub, Instagram, LinkedIn, LocationOn, Phone, YouTube } from "@mui/icons-material";
import { setEditData } from "../../services/EditingSlice";

const Template2 = ({templateDetails, screen, refs}) => {
    const template = useSelector(templateSelector);
    const theme = useSelector(themeSelector);
    const fontFamily = useSelector(fontFamilySelector);

    const dispatch = useDispatch();

    const styles = {
        contact: {
            backgroundColor: theme.light.secondary,
            height: '100vh',
            textAlign: 'center',
            overflow: 'hidden'
        },
        btnProps: {
            backgroundColor: theme.light.primary,
            color: theme.light.secondary
        },
        media: {
            display: 'flex',
            justifyContent: 'center'
        },
        contactContainer: {
            border: `1px solid ${theme.light.secondary}`,
            borderImage: `repeating-linear-gradient(45deg, transparent, transparent 5px, ${theme.light.secondary} 6px, ${theme.light.secondary} 15px) 20/1rem`,
            margin: '5vw 0',
            backgroundColor: theme.light.info
        },
        scrollContainer: {
            height: '100vh',
            overflowY: 'scroll'
        }
    }

    const openEdit = (contactDetails) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: contactDetails,
            editingFields: [
                {key: 'name', label: 'Name'},
                {key: 'designation', label: 'Designation'},
                {key: 'description', label: 'Description'},
                {key: 'linkedInLink', label: 'LinkedIn Link'},
                {key: 'instagramLink', label: 'Instagram Link'},
                {key: 'youtubeLink', label: 'Youtube Link'},
                {key: 'githubLink', label: 'Github Link'},
                {key: 'email', label: 'Email'},
                {key: 'phone', label: 'Phone'},
                {key: 'address', label: 'Address'}
            ],
            path: `contact.name,contact.designation,contact.description,contact.linkedInLink,contact.instagramLink,contact.youtubeLink,contact.githubLink,contact.email,contact.phone,contact.address`
        }))
    }

    return(
        <Box>
            <Grid container>
                <Grid item xs={12} sm={12} md={9} lg={9} style={window.innerWidth > 900 ? styles.scrollContainer : {}}>
                    {templateDetails?.sections?.map((ele, i) => (
                        <Grid container key={i} ref={ref => { refs.current[i] = ref }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Sections sectionDetails={ele} />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                {templateDetails?.contact && <Grid item xs={12} sm={12} md={3} lg={3} style={styles.contact}>
                    <Box style={{padding: '16vh 2vw 0 2vw'}} onDoubleClick={() => {
                        openEdit(templateDetails?.contact);
                    }}>
                        <Box style={styles.aboutContainer}>
                            <Typography variant="h2" style={{...getTextStyles(template, "header2", {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary}}}>{templateDetails.contact.name}</Typography>
                            <Typography variant="h3" style={{...getTextStyles(template, "header3", {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary, paddingBottom: '2vw'}}} onDoubleClick={() => {
                                dispatch(setEditData({
                                    editType: 'text',
                                    editContent: templateDetails.contact.designation,
                                    editTitle: 'Edit Designation',
                                    path: 'contact.designation'
                                }))
                            }}>{templateDetails.contact.designation}</Typography>
                            <Typography variant="body" style={{...getTextStyles(template, "body", {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary}}} onDoubleClick={() => {
                                dispatch(setEditData({
                                    editType: 'text',
                                    editContent: templateDetails.contact.description,
                                    editTitle: 'Edit Description',
                                    path: 'contact.description'
                                }))
                            }}>{templateDetails.contact.description}</Typography>
                        </Box>
                        <Box style={styles.media}>
                            <Button style={{...getButtonStyles('square', {}), ...styles.btnProps}} onClick={() => window.location.href = templateDetails?.contact?.linkedInLink}><LinkedIn style={{fontSize: '20px'}}/></Button>
                            <Button style={{...getButtonStyles('square', {}), ...styles.btnProps}} onClick={() => window.location.href = templateDetails?.contact?.instagramLink}><Instagram style={{fontSize: '20px'}}/></Button>
                            <Button style={{...getButtonStyles('square', {}), ...styles.btnProps}} onClick={() => window.location.href = templateDetails?.contact?.youtubeLink}><YouTube style={{fontSize: '20px'}}/></Button>
                            <Button style={{...getButtonStyles('square', {}), ...styles.btnProps}} onClick={() => window.location.href = templateDetails?.contact?.githubLink}><GitHub style={{fontSize: '20px'}}/></Button>
                        </Box>
                        <Box style={styles.contactContainer}>
                            <List style={{padding: '2vw 0.5vw'}}>
                                <ListItem style={{overflowWrap:'break-word', wordWrap:'break-word', wordBreak: 'break-all'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}><Email /></ListItemIcon>
                                    <ListItemText><Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary, cursor: 'pointer'}}} onClick={() => window.location.href = `mailto:${templateDetails.contact.email}`}>{templateDetails?.contact?.email}</Typography></ListItemText>
                                </ListItem>
                                <ListItem style={{overflowWrap:'break-word', wordWrap:'break-word', wordBreak: 'break-all'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}><Phone /></ListItemIcon>
                                    <ListItemText><Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary, cursor: 'pointer'}}} onClick={() => window.location.href = `tel:${templateDetails.contact.phone}`}>{templateDetails?.contact?.phone}</Typography></ListItemText>
                                </ListItem>
                                <ListItem style={{overflowWrap:'break-word', wordWrap:'break-word', wordBreak: 'break-all'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}><LocationOn /></ListItemIcon>
                                    <ListItemText><Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary, cursor: 'pointer'}}}>{templateDetails.contact.address}</Typography></ListItemText>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                </Grid>}
            </Grid>
        </Box>
    )
}

export default Template2;