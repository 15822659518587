import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, themeSelector, templateSelector } from "../../services/ThemeSlice";
import { Box, Grid, Typography } from "@mui/material";
import { getTextStyles } from "../../style/textStyles";
import { PropTypes } from "prop-types";
import { resourcesSelector } from "../../services/TemplateSlice";
import { setEditData } from "../../services/EditingSlice";

const Card21 = ({content, draggable, onDoubleClick, onDragEnd, onDragOver}) => {
    const template = useSelector(templateSelector);
    const theme = useSelector(themeSelector);
    const fontFamily = useSelector(fontFamilySelector);
    const resources = useSelector(resourcesSelector);
    const dispatch = useDispatch();

    const styles = {
        back:{
            width: '13vw',
            height: 'auto',
            background: `linear-gradient(${theme.light.primary} 25%, ${theme.light.default} 75%)`,
            padding: '2vw',
            marginBottom: '40px'
        },
        main:{
            background: theme.light.tertiary,
            width: '80vw',
            height: 'auto',
        },
        image:{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        },
        front:{
            background: theme.light.default,
            marginTop: window.innerWidth > 900 ? '-3vw' : 0,
            marginLeft: window.innerWidth > 900 ? '5vw': 0,
            width: window.innerWidth > 900 ? '70%' : '100%',
            boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
        },
        content: {
            padding: '2vw'
        },
        tech: {
            margin: '2vw 0',
            padding: '1vw',
            background: theme.light.secondary,
        }
    }

    const openEditImage = (img) => {
        dispatch(setEditData({
            editType: 'image',
            editContent: img,
            editTitle: 'Edit Picture',
            path: img
        }))
    }

    return(
        <Box style={styles.back}>
            <Box style={styles.main}>
                <Grid container
                    style={{cursor: draggable ? 'move' : 'default'}}
                    draggable={draggable}
                    onDragEnd={onDragEnd}
                    onDragOver={onDragOver}
                >
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{padding: '3vw'}} onDoubleClick={() => openEditImage(content?.image)}>
                        <img src={resources[content?.image]} alt="project" style={styles.image}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} onDoubleClick={onDoubleClick}>
                        <Box style={styles.front}>
                            <Box style={styles.content}>
                                <Typography variant="h2" style={{...getTextStyles(template, 'header2', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, fontWeight: '900', paddingBottom: '3vw'}}}>{content?.name}</Typography>
                                <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{content?.intro}</Typography>
                            </Box>
                            <Box style={styles.tech}>
                                <Typography variant="h5" style={{...getTextStyles(template, 'header5', {}), ...{color: theme.light.info, fontFamily:fontFamily.secondary}}}>{content?.technologies}</Typography>
                            </Box>
                            <Box style={{padding: '1vw'}}></Box>
                        </Box>
                    </Grid>
                    </Grid>
            </Box>
        </Box>
    )
}

Card21.propTypes = {
    content: PropTypes.shape({
        name: PropTypes.string.isRequired,
        intro: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        technologies: PropTypes.string.isRequired,
        btnText: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
    })
}

export default Card21;