import { Box, Grid, Typography } from "@mui/material";
import { getTextStyles } from "../../style/textStyles";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { PropTypes } from 'prop-types';
import { resourcesSelector } from "../../services/TemplateSlice";
import { setEditData } from "../../services/EditingSlice";
import { generateEditPath } from "../../editingComponents/Helpers";

const Card22 = ({content}) => {
    const template = useSelector(templateSelector);
    const theme = useSelector(themeSelector);
    const fontFamily = useSelector(fontFamilySelector);
    const resources = useSelector(resourcesSelector);

    const dispatch = useDispatch();

    const styles = {
        container: {
            padding: '100px 5vw 5vw 5vw',
            display: 'flex',
            alignItems: 'center'
        },
        imageContainer: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 0
        },
        imageWrap: {
            width: '100%',
            height: '350px'
        },
        image: {
            width: '80%',
            height: 'auto'
        },
        content: {
            width: '100%',
            paddingBottom: '2vw',
            height: 'auto',
            background: '#eef0f2',
        },
        corner: {
           position: 'absolute',
           top: '-40px',
           left: '5px',
           border: '2px solid black',
           borderTop: '2px solid black',
           borderBottom: '2px solid black',
           width: '80%',
           height: '100%',
           zIndex: -1
        }
    }

    const openEditImage = () => {
        dispatch(setEditData({
            editType: 'image',
            editTitle: 'Edit Image',
            path: 'PROFILE_IMAGE'
        }))
    }

    const openEdit = (ele, i) => {
        const editingFields = [
            {key: 'name', label: 'Name'},
            {key: 'content', label: 'Description'}
        ];
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: ele,
            editingFields: editingFields,
            regenerateSection: 'ABOUT_ME',
            editTitle: 'Edit About Details',
            maxWidth: 'lg',
            fullWidth: true,
            path: generateEditPath('sections[0].about', editingFields)
        }))
    }

    return(
        <Grid container style={styles.container}>
            <Grid item xs={12} sm={12} md={4} lg={4} onDoubleClick={() => openEditImage()}>
                <Box style={styles.imageContainer}>
                    <img src={resources[content?.image]} style={styles.image} alt="about"/>
                    <Box style={styles.corner}></Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} onDoubleClick={() => openEdit(content)}>
                <Box style={{padding: '0 2vw 0 10vw'}}>
                    <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{color: theme.light.primary, fontFamily: fontFamily.secondary, paddingBottom: '2vw'}}}>{content?.name}</Typography>
                    <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{content?.content}</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

Card22.propTypes = {
    content: PropTypes.shape({
        image: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired
    })
}
export default Card22;