import { Box, Grid } from '@mui/material';
import Sections from '../Sections';


const Template1 = ({templateDetails, screen, refs}) => {
    console.log(refs)
    return (
        <Box>
          {templateDetails?.sections?.map((ele, i) => (
              <Grid container key={i} ref={ref => { refs.current[i] = ref }} style={i === 0 ? {} : {padding: '4vw 1vw'}}>
                <Grid item xs={12} md={12} lg={12}>
                  <Sections sectionDetails={ele} refs={screen}/>
                </Grid>
              </Grid>
          ))} 
        </Box>
    );
}

export default Template1;