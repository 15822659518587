import Card4 from "../Card/Card4";
import Card6 from "../Card/Card6";
import { Box, Grid, Typography } from "@mui/material";
import { getTextStyles } from "../../style/textStyles";
import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import Card7 from "../Card/Card7";

const Home1 = ({homeDetails}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const showCount = homeDetails?.totalCount || homeDetails?.count;

    return(
        <Box>
            <Grid container style={{marginTop: '5vw'}}>
                <Card4 contentDetails={homeDetails?.bannerDetails}/>
            </Grid>
            {homeDetails?.missionStatements && <Grid container style={{width: '100%', display:'flex', justifyContent: 'center', paddingTop: '10vw'}}>
                {homeDetails?.missionStatements?.map((stmt)=> (
                    <Box style={{margin: '2vw'}}>
                        <Card6 content={stmt}/> 
                    </Box>  
                ))}
            </Grid>}
            {showCount && <Grid container style={{paddingTop: '5vw'}}>
                {homeDetails?.totalCount && <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography style={getTextStyles(template, 'header1', {color: theme.light.info, fontFamily:fontFamily.primary})}>{homeDetails?.totalCount}</Typography>
                </Grid>}
                {homeDetails?.count && <Card7 content={homeDetails.count}/>}
            </Grid>}
            {homeDetails?.lastSections && homeDetails?.lastSections?.map(section => (
                <Grid container style={{paddingTop: '5vw'}}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography style={getTextStyles(template, 'header2', {color: theme.light.info, fontFamily:fontFamily.primary, textAlign: 'center'})}>{section?.sectionTitle}</Typography>
                    </Grid>
                    <Card7 content={section?.imagesList}/>
                </Grid>
            ))}
        </Box>
    )
}

export default Home1;