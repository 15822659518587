import { Box, Button, Divider, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { getButtonStyles } from "../../style/buttonStyles";
import { Email, GitHub, Instagram, LinkedIn } from "@mui/icons-material";
import { setEditData } from "../../services/EditingSlice";

const Contact4 = ({details}) => {
    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)

    const dispatch = useDispatch();

    const styles = {
        outer: {
            display: 'flex',
            flexDirection: window.innerWidth > 900 ? 'row' : 'column',
            background: theme.light.info,
        },
        left: {
            background: theme.light.primary,
            clipPath: 'polygon(0% 0%, 90% 0%, 100% 100%, 0% 100%)',
            padding: '4vw',
            flexGrow: 0.3
        },
        right: {
            flexGrow: 1,
            padding: '4vw',
        },
        btnProps: {
            backgroundColor: theme.light.info,
            color: theme.light.tertiary,
            borderColor: theme.light.info,
            fontFamily: fontFamily.primary
        },
        divider: {
            background: theme.light.default,
            margin: '4vw'
        }
    }

    const handleContacts = (link) => {
        window.location.href = link
    }

    const openEditName = (det) => {
        dispatch(setEditData({
            editType: 'text',
            editContent: det,
            editTitle: 'Edit Name',
            path: 'name',
            selector: 'contactDetails'
        }))
    }

    const openEditSocMedia = (det) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editTitle: 'Edit Contact Info',
            editContent: det,
            editingFields: [
                {key: 'linkedInLink', label: 'Linked In'},
                {key: 'instagramLink', label: 'Insta'},
                {key: 'githubLink', label: 'Github'}
            ],
            path: `linkedInLink,instagramLink,githubLink`,
            selector: 'contactDetails'
        }))
    }

    const openEditContact = (det, i) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editTitle: 'Edit Contact Info',
            editContent: det,
            editingFields: [
                {key: 'heading', label: 'Heading'},
                {key: 'text', label: 'Value'}
            ],
            path: `contactInfo[${i}].heading,contactInfo[${i}].text`,
            selector: 'contactDetails'
        }))
    }

    const openEditEducation = (det, i) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editTitle: 'Edit Education Info',
            editContent: det,
            editingFields: [
                {key: 'college', label: 'College'},
                {key: 'degree', label: 'Degree'},
                {key: 'stream', label: 'Stream'},
                {key: 'GPA', label: 'GPA'}
            ],
            path: `educationInfo[${i}].college,educationInfo[${i}].degree,educationInfo[${i}].stream,educationInfo[${i}].GPA`,
            selector: 'contactDetails'
        }))
    }

    return(
        <Box style={styles.outer}>
            <Box style={styles.left}>
                <Typography variant="h2" style={{...getTextStyles(template, 'header2', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, textAlign: 'left'}}} onDoubleClick={() => openEditName(details?.name)}>{details?.name}</Typography>
                <Box style={{paddingTop: '2vw'}}>
                    {details?.contactInfo?.map((ele, i) => (
                        <Box key={i} style={{display:'flex', flexDirection: 'column'}} onDoubleClick={() => openEditContact(ele, i)}>
                            <Typography variant="h5" style={{...getTextStyles(template, 'header5', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, textAlign: 'left', fontWeight: '800'}}}>{ele?.heading}</Typography>
                            {ele?.heading === "Email" ? <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, textAlign: 'left', cursor: 'pointer'}}} onClick={() => window.location.href = `mailto:${ele?.text}`}>{ele?.text}</Typography>:
                            (ele?.heading === "Phone" ? <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, textAlign: 'left', cursor: 'pointer'}}} onClick={() => window.location.href = `tel:${ele?.text}`}>{ele?.text}</Typography>:
                            <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, textAlign: 'left'}}}>{ele?.text}</Typography>)}
                        </Box>
                    ))}
                </Box>
                <Box style={{display: 'flex', justifyContent: 'start', paddingTop: '2vw'}} onDoubleClick={() => openEditSocMedia(details)}>
                    <Button style={getButtonStyles('floating', styles.btnProps)} onClick={() => handleContacts(details?.linkedInLink)}><LinkedIn style={{fontSize: '2.5vmin'}}></LinkedIn></Button>
                    <Button style={getButtonStyles('floating', styles.btnProps)} onClick={() => handleContacts(details?.instagramLink)}><Instagram style={{fontSize: '2.5vmin'}}></Instagram></Button>
                    <Button style={getButtonStyles('floating', styles.btnProps)} onClick={() => handleContacts(details?.emailLink)}><Email style={{fontSize: '2.5vmin'}}></Email></Button>
                    <Button style={getButtonStyles('floating', styles.btnProps)} onClick={() => handleContacts(details?.githubLink)}><GitHub style={{fontSize: '2.5vmin'}}></GitHub></Button>
                </Box>
            </Box>
            <Box style={styles.right}>
                <Typography variant="h2" style={{...getTextStyles(template, 'header2', {}), ...{color: theme.light.default, fontFamily: fontFamily.primary, textAlign: 'center', lineHeight: 0}}}>Education</Typography>
                <Box style={{paddingTop: '5vw', display: 'flex', flexDirection: window.innerWidth > 900 ? 'row' : 'column'}}>
                    {details?.educationInfo?.map((ele, i) => (
                        <Box key={i} style={{display:'flex', flexDirection: 'column', flexGrow: 1}} onDoubleClick={() => openEditEducation(ele, i)}>
                            <Typography variant="h4" style={{...getTextStyles(template, 'header4', {}), ...{color: theme.light.default, fontFamily: fontFamily.primary, textAlign: 'left', lineHeight: 2}}}>{ele.college}</Typography>
                            <Typography variant="h5" style={{...getTextStyles(template, 'header5', {}), ...{color: theme.light.default, fontFamily: fontFamily.primary, textAlign: 'left', paddingBottom: '1vmin'}}}>{ele.degree}</Typography>
                            <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.default, fontFamily: fontFamily.primary, textAlign: 'left'}}}>{ele.stream}</Typography>
                            {ele.GPA?<Typography variant="h6" style={{...getTextStyles(template, 'header6', {}), ...{color: theme.light.default, fontFamily: fontFamily.primary, textAlign: 'left'}}}>GPA: {ele.GPA}</Typography> : ''}
                        </Box>
                    ))}
                </Box>
                <Divider style={styles.divider}/>
                <Typography variant="body" style={getTextStyles(template, 'body', {color: theme.light.tertiary, fontFamily: fontFamily.primary})}>{details?.credits}</Typography>
            </Box>
        </Box>
    )
}

export default Contact4;