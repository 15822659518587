export const fontTemplates = {
    template1: {
        primary: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif`
    },
    template2: {
        primary: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif`
    },
    template3: {
        primary: `'Oswald', sans-serif`,
        secondary: `'Questrial', sans-serif`
    },
    template4: {
        primary: `'Poppins', sans-serif`
    },
    template5: {
        primary: `'Geologica', sans-serif`,
        secondary: `'Cherry Bomb One', cursive`
    },
    template6: {
        primary: `'Mukta', sans-serif`,
        secondary: `'Dancing Script', cursive`
    }
}

export const themeTemplates = {
    template1: {
        default: "#ffffff",
        primary: "#353535",
        secondary: "#284b63",
        tertiary: "#3c6e71",
        info: "#d9d9d9"
    },
    template2: {
        default: '#ffffff',
        primary: '#252323',
        secondary: '#dad2bc',
        tertiary: '#a99985',
        info: '#f5f1ed'
    },
    template3: {
        default: '#ffffff',
        primary: '#104c43',
        secondary: '#f2f2f2',
        tertiary: '#8751bd',
        info: '#1f9785'
    },
    template4: {
        default: '#ffffff',
        primary: '#fcf300',
        secondary: '#ffc300',
        tertiary: '#f6f6f6',
        info: '#000814'
    },
    template5: {
        default: '#ffffff',
        primary: "#f7a399",
        secondary: '#fbc3bc',
        tertiary: "#ffe3e0",
        info: "#252422"
    },
    template6: {
        default: '#ffffff',
        primary: '#000000',
        secondary: '#a5a5a5',
        tertiary: '#eef0f2',
        info: '#393d3f'
    }
}