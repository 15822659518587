import { Box, Button, Typography } from "@mui/material";
import { useRef, useState } from "react";

const FileUpload = ({fileDropped}) => {
    const inputRef = useRef(null)

    const styles = {
        mainContainer: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '2vw'
        },
        uploadContainer: {
            width: '70vw',
            height: '50vh',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '20px'
        },
        text:{
            fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif`,
            fontSize: 'calc(16px + 1vw)',
            paddingBottom: '20px'
        }
    }

    const [error, setError] = useState(false)
    const [text, setText] = useState('Drag and drop your file here or') 

    const handleDrag = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        if(evt.type === "dragenter" || evt.type === "dragover"){
            console.log("file dragged")
        }else if(evt.type === "dragleave"){
            console.log("file dropped")
        }
    }
    
    const handleChange = (evt) => {
        evt.preventDefault();

        console.log(evt)

        if(evt.target.files && evt.target.files[0]){
            if(typeof(fileDropped) === 'function') {
                setError(false)
                setText('Drag and drop your file here or')
                setTimeout(()=> {
                    fileDropped(evt.target.files[0]);
                },100)
            }
        }
    }

    const handleDrop = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        if(evt.dataTransfer.files && evt.dataTransfer.files.length > 0){
            if(evt.dataTransfer.files.length > 1){
                setError(true)
                setText('Only a single file can be uploaded')
            }else if(!evt.dataTransfer.files[0].type.includes('image')){
                setError(true)
                setText('Please upload only image files')
            }else{
                if(typeof(fileDropped) === 'function') {
                    setError(false)
                    setText('Drag and drop your file here or')
                    fileDropped(evt.dataTransfer.files[0]);
                }
            }
        }
    }

    const handleClick = () => {
        inputRef.current.click()
    }

    return(
        <Box style={styles.mainContainer}>
            <form onDragEnter={handleDrag} onDragOver={handleDrag} onDragLeave={handleDrag} onDrop={handleDrop} onSubmit={(e) => {e.preventDefault(); e.stopPropagation();}}>
                <input type="file" multiple={false} ref={inputRef} onChange={handleChange} style={{display: 'none'}} accept="image/*"/>
                <Box style={error ? {...styles.uploadContainer, ...{border: '2px dashed red', background: 'rgb(255, 222, 222)'}} : {...styles.uploadContainer, ...{border: '2px dashed grey', background: 'rgb(250, 250, 250)'}}}>
                    <Typography variant="h4" style={styles.text}>{text}</Typography>
                    <Button variant="outlined" onClick={handleClick}>Upload a file</Button>
                </Box> 
            </form>
        </Box>
    )
}

export default FileUpload;