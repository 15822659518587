import { Box } from "@mui/system";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Card6 from "../Card/Card6";
import Card10 from "../Card/Card10";
import { Grid } from "@mui/material";
import { pathNameSelector } from "../../services/PathSlice";

const Details5 = ({details}) => {
    const pathName = useSelector(pathNameSelector)

    useEffect(()=>{
        var current = document.getElementById("current")
        var previous = document.getElementById("previous")

        if(window.innerWidth > 900){
            current.style.paddingTop = `${previous.offsetHeight/2}px`
        }else{
            current.style.paddingTop = `${previous.offsetHeight}px`
        }  
    },[pathName])

    return(
        <Box style={{padding: '3vw 0 0 0'}}>
            {details?.mainContent && <Box id="previous">
                <Card6 content={details?.mainContent}/>
            </Box>}
            {details?.content && <Box style={{padding: '0 15vw'}} id="current">
                <Grid container spacing={5}>
                    {details?.content.map((ele)=>(
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Card10 content={ele}/>
                        </Grid>
                    ))}
                </Grid>
            </Box>}
        </Box>
    )
}

export default Details5;