import { createSlice } from "@reduxjs/toolkit";

const editingSlice = createSlice({
    name: "editingHandler",
    initialState: {
        editType: '',
        editContent: '',
        editTitle: '',
        path: '',
        list: [],
        listValue: '',
        listFields: [],
        editingFields: [],
        maxWidth: '',
        fullWidth: false,
        selector: '',
        editSubType: '',
        arrayContent: null,
        arrayIndex: '',
        deletePath: '',
        regenerateSection: null
    },
    reducers: {
        setEditData: (state, action) => {
            state.editType = action.payload?.editType;
            state.editContent = action.payload?.editContent;
            state.editTitle = action.payload?.editTitle;
            state.path = action.payload?.path;
            state.list = action.payload?.list;
            state.listValue = action.payload?.listValue;
            state.listFields = action.payload?.listFields;
            state.editingFields = action.payload?.editingFields;
            state.maxWidth = action.payload?.maxWidth;
            state.fullWidth = action.payload?.fullWidth;
            state.selector = action.payload?.selector;
            state.editSubType = action.payload?.editSubType;
            state.arrayContent = action.payload?.arrayContent;
            state.arrayIndex = action.payload?.arrayIndex;
            state.deletePath = action.payload?.deletePath;
            state.regenerateSection = action.payload?.regenerateSection;
        }
    }
})

export const { setEditData } = editingSlice.actions;

export const editTypeSelector = (state) => state.editingHandler.editType;
export const editContentSelector = (state) =>  state.editingHandler.editContent;
export const editTitleSelector = (state) => state.editingHandler.editTitle;
export const pathSelector = (state) => state.editingHandler.path;
export const listSelector = (state) => state.editingHandler.list;
export const listValueSelector = (state) => state.editingHandler.listValue;
export const listFieldsSelector = (state) => state.editingHandler.listFields;
export const editingFieldsSelector = (state) => state.editingHandler.editingFields;
export const maxWidthSelector = (state) => state.editingHandler.maxWidth;
export const fullWidthSelector = (state) => state.editingHandler.fullWidth;
export const selectorSelector = (state) => state.editingHandler.selector;
export const editSubTypeSelector = (state) => state.editingHandler.editSubType;
export const arrayContentSelector = (state) => state.editingHandler.arrayContent;
export const arrayIndexSelector = (state) => state.editingHandler.arrayIndex;
export const deletePathSelector = (state) => state.editingHandler.deletePath;
export const regenerateSectionSelector = (state) => state.editingHandler.regenerateSection;

export default editingSlice.reducer;