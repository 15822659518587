import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { setEditData } from "../../services/EditingSlice";
import { Add } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { authTokenSelector, editPortfilio } from "../../services/TemplateSlice";
import { useState } from "react";
import { shiftElementsByPosition } from "../../editingComponents/Helpers";

const Experience2 = ({details}) => {
    const template = useSelector(templateSelector);
    const theme = useSelector(themeSelector);
    const fontFamily = useSelector(fontFamilySelector);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authToken = useSelector(authTokenSelector);
    const [dragState, setDragState] = useState(null);

    const dispatch = useDispatch();

    const styles = {
        experiences: {
            backgroundColor: theme.light.info,
        }
    }

    const openEdit = (ele, i, arrayContent) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: ele,
            editSubType: typeof(i) === 'number' ? '' : 'array',
            arrayContent: arrayContent === undefined ? ele : arrayContent,
            arrayIndex: i,
            editingFields: [
                {
                    key: 'company',
                    label: 'Company Name',
                },
                {
                    key: 'year',
                    label: 'Duration'
                },
                {
                    key: 'role',
                    label: 'Role Name'
                },
                {
                    key: 'description',
                    label: 'Role Description'
                }
            ],
            editTitle: 'Edit Experience',
            path: typeof(i) === 'number' ? `sections[1].content[${i}].company,sections[1].content[${i}].year,sections[1].content[${i}].role,sections[1].content[${i}].description` : `sections[1].content`,
            deletePath: `sections[1].content`,
        }))
    }

    return(
        <>
            {details && <Box style={styles.experiences}>
                <Box style={{padding: '2vw'}}>
                    <Typography variant="h3" style={{...getTextStyles(template, "header3", {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary, textTransform: 'uppercase', textAlign: 'center'}}}>{details?.title}
                    {Boolean(authToken) && authToken === queryParams.get('authToken') && <IconButton style={{marginBottom: 10, color: '#000', marginLeft: 10}} onClick={() => openEdit(undefined, undefined, details?.content)}><Add/></IconButton>}
                    </Typography>
                    <Box style={{padding: '1vw 0'}}>
                        {details?.content?.map((ele, i) => (
                            <Box style={{padding: '0.5vw', cursor: Boolean(authToken) && authToken === queryParams.get('authToken') ? 'move' : 'default'}} key={i} 
                            draggable={Boolean(authToken) && authToken === queryParams.get('authToken')} 
                            onDragEnd={(event) => {
                                dispatch(editPortfilio(`sections[1].content`, shiftElementsByPosition(details?.content, i + 1, dragState + 1), queryParams.get('authToken'), null, 'array'));
                            }} 
                            onDragOver={() => setDragState(i)} onDoubleClick={() => {
                                openEdit(ele, i, details?.content);
                            }}>
                                <Box style={{display: 'flex'}}>
                                    <Typography variant="h5" style={{...getTextStyles(template, "header5", {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary, flex: 1}}}>{ele.company}</Typography>
                                    <Typography variant="h6" style={{...getTextStyles(template, "header6", {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary}}}>{ele.year}</Typography>
                                </Box>
                                <Typography variant="h6" style={{...getTextStyles(template, "header6", {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary}}}>{ele.role}</Typography>
                                <Typography variant="body" style={{...getTextStyles(template, "body", {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary}}}>{ele.description}</Typography>
                                <Divider style={{backgroundColor: theme.light.tertiary, opacity: '0.5', marginTop: '1vw'}}/>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>}
        </>
    )
}

export default Experience2;