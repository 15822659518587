import { useSelector } from "react-redux";
import { themeSelector } from "../../services/ThemeSlice";
import { Grid } from "@mui/material";
import Card18 from "../Card/Card18";
import Card19 from "../Card/Card19";

const About5 = ({details}) => {
    const theme = useSelector(themeSelector)

    const styles = {
        left: {
            marginTop: '30px',
            position: 'relative',
            background: theme.light.secondary,
            width:'100%',
            height: '100%'
        }
    }

    return(
        <Grid container style={{height: window.innerWidth > 900 ? '100%' : 'auto',width: '100%'}}>
            <Grid item xs={12} sm={12} md={3} lg={3} style={styles.left}>
                <Card19 content={details}/>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} style={styles.right}>
                <Card18 content={details}/>
            </Grid>
        </Grid>
    )
}

export default About5;