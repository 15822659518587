import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
    name: 'themeHandler',
    initialState: {
        theme: {
            light: {
                default: "#ffffff",
                primary: "#353535",
                secondary: "#284b63",
                tertiary: "#3c6e71",
                info: "#d9d9d9"
            }
        },
        fontFamily: `'Times New Roman', Times, serif`,
        template: null
    },
    reducers: {
        setThemeReducer: (state, action) => {
            state.theme = action.payload
        },
        setFontFamilyReducer: (state, action) => {
            state.fontFamily = action.payload
        },
        setTemplateReducer: (state, action) => {
            state.template = action.payload
        }
    }
})

export function setTheme(theme) {
    return (dispatch) => {
        dispatch(setThemeReducer(theme))
    }
}

export function setFontFamily(fontFamily) {
    return (dispatch) => {
        dispatch(setFontFamilyReducer(fontFamily))
    }
}

export function setTemplate(template) {
    return (dispatch) => {
        dispatch(setTemplateReducer(template))
    }
}

export const { setThemeReducer, setFontFamilyReducer, setTemplateReducer } = themeSlice.actions;
export const themeSelector = (state) => state.themeHandler.theme;
export const fontFamilySelector = (state) => state.themeHandler.fontFamily;
export const templateSelector = (state) => state.themeHandler.template;
export default themeSlice.reducer;