import About1 from './About1';
import About2 from './About2';
import About4 from './About4';
import About5 from './About5';
import About6 from './About6';
import Experience1 from './Experience1';
import Experience2 from './Experience2';
import Experience4 from './Experience4';
import Experience5 from './Experience5';
import Experience6 from './Experience6';
import Project1 from './Project1';
import Project2 from './Project2';
import Project4 from './Project4';
import Project5 from './Project5';
import Project6 from './Project6';

const Sections = ({sectionDetails, refs}) => {
    switch(sectionDetails?.selector){
        case 'ABOUT1': return(<About1 details={sectionDetails} refs={refs}/>)
        case 'ABOUT2': return(<About2 details={sectionDetails} />)
        case 'ABOUT4': return(<About4 details={sectionDetails} />)
        case 'ABOUT5': return(<About5 details={sectionDetails} />)
        case 'ABOUT6': return(<About6 details={sectionDetails} />)
        case 'EXPERIENCE1': return(<Experience1 details={sectionDetails} />)
        case 'EXPERIENCE2': return(<Experience2 details={sectionDetails} />)
        case 'EXPERIENCE4': return(<Experience4 details={sectionDetails} />)
        case 'EXPERIENCE5': return(<Experience5 details={sectionDetails} />)
        case 'EXPERIENCE6': return(<Experience6 details={sectionDetails} />)
        case 'PROJECT1': return(<Project1 details={sectionDetails} />)
        case 'PROJECT2': return(<Project2 details={sectionDetails} />)
        case 'PROJECT4': return(<Project4 details={sectionDetails} />)
        case 'PROJECT5': return(<Project5 details={sectionDetails} />)
        case 'PROJECT6': return(<Project6 details={sectionDetails} />)
        default: return;
    }
}

export default Sections;