import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
    name: 'notificationHandler',
    initialState: {
        body: '',
        type: '',
        toastId: ''
    },
    reducers: {
        setBody: (state, action) => {
            state.body = action.payload;
        },
        setType: (state, action) => {
            state.type = action.payload;
        },
        setToastId: (state, action) => {
            state.toastId = action.payload;
        }
    }
})

export const { setBody, setType, setToastId } = notificationSlice.actions;

export const bodySelector = (state) => state.notificationHandler.body;
export const typeSelector = (state) => state.notificationHandler.type;
export const toastIdSelector = (state) => state.notificationHandler.toastId;

export default notificationSlice.reducer;