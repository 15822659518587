
const prodUrl = 'https://api.resumlink.com/pviewerApi/';
const testUrl = 'https://testbe.tedoratech.com/pviewerApi/';
// const baseUrl = 'http://localhost:8000/pviewerApi/';

function getFullUrl(endpoint){
    const baseUrl = window.location.hostname.includes('testsystems') ? testUrl : prodUrl;
    return `${baseUrl}${endpoint}`;
}

const urls = {
    fecthPortfolio: getFullUrl('templatedet'),
    editPortfolio: getFullUrl('templatedet/edit'),
    regenerateSection: getFullUrl('templatedet/regenerate'),
    editImage: getFullUrl('templatedet/editimage'),
    createSession: getFullUrl('session/createsession'),
    syncAnalytics: getFullUrl('analytics/syncAnalytics'),
}

export default urls;