import { Box, List, ListItem, Typography } from "@mui/material";
import { getTextStyles } from "../../style/textStyles";
import { PropTypes } from 'prop-types'
import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";

const Card2 = ({content, doubleClick}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const styles = {
        back: {
            background: `repeating-linear-gradient(-25deg, transparent 0, transparent 2px, ${theme.light.tertiary} 2px, ${theme.light.tertiary} 4px)`,
            border: `1px solid ${theme.light.secondary}`,
            position: 'relative',
            width: '90%',
            color: 'transparent',
            height: '100%',
            padding: '1.5vw 0'
        },
        front: {
            position: 'absolute',
            top: '20px',
            left: '20px',
            background: theme.light.default,
            width: '100%',
            height: '100%',
        },
        title: {
            background: theme.light.tertiary,
            padding: '1vw'
        },
        content: {
            padding: '0 1vw'
        }
    }

    return(
        <Box style={styles.back} className="appear-box" onDoubleClick={() => {
            if(typeof(doubleClick) === 'function'){
                doubleClick();
            }
        }}>
            <Box>
                <Typography variant="h4" style={getTextStyles(template, 'header4', {color: 'transparent', fontFamily: fontFamily.primary})}>{content.company}</Typography>
                <Typography variant="h5" style={getTextStyles(template, 'header5', {color: 'transparent', fontFamily: fontFamily.primary})}>{content.role}</Typography>
                <Typography variant="h6" style={getTextStyles(template, 'header6', {color: 'transparent', fontFamily: fontFamily.primary})}>{content.year}</Typography>
            </Box>
            <Box style={styles.content}>
                <List>
                {content.description.split('\n').map((ele, index)=>(
                    <ListItem key={index} style={{padding: 0}}>
                        <Typography variant="body" style={{...getTextStyles(template, 'body', {color: 'transparent', fontFamily: fontFamily.primary}), ...{textAlign: 'left', padding:'0.5vmin 0'}}}>{ele}</Typography>
                    </ListItem>
                ))}
                </List>
            </Box>
            <Box style={styles.front} boxShadow={6}>
                <Box style={styles.title}>
                    <Typography variant="h4" style={getTextStyles(template, 'header4', {color: 'white', fontFamily: fontFamily.primary})}>{content.company}</Typography>
                    <Typography variant="h5" style={getTextStyles(template, 'header5', {color: 'white', fontFamily: fontFamily.primary})}>{content.role}</Typography>
                    <Typography variant="h6" style={getTextStyles(template, 'header6', {color: 'white', fontFamily: fontFamily.primary})}>{content.year}</Typography>
                </Box>
                <Box style={styles.content}>
                    <List>
                    {content.description.split('\n').map((ele, index)=>(
                        <ListItem key={index} style={{padding: 0}}>
                            <Typography variant="body" style={{...getTextStyles(template, 'body', {color: theme.light.primary, fontFamily: fontFamily.primary}), ...{textAlign: 'left', padding:'0.5vmin 0'}}}>{ele}</Typography>
                        </ListItem>
                    ))}
                    </List>
                </Box>
            </Box>
        </Box>
    )
}

Card2.propTypes = { 
    content : PropTypes.shape({
        company: PropTypes.string.isRequired,
        role:  PropTypes.string.isRequired,
        year: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
    })
}

export default Card2;