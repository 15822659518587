import { Box } from "@mui/material";
import Card22 from "../Card/Card22";
import Card23 from "../Card/Card23";

const About6 = ({details}) => {
    return(
        <>
            {details && <Box>
                <Card22 content={details?.about}/>
                <Card23 content={details?.skills}/>
            </Box>} 
        </>
    )
}

export default About6;