import { Box } from "@mui/system";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import Card6 from "../Card/Card6";
import { Grid, Typography } from "@mui/material";
import { pathNameSelector } from "../../services/PathSlice";
import { getTextStyles } from "../../style/textStyles";

const Details9 = ({details}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const pathName = useSelector(pathNameSelector)

    useEffect(()=>{
        var current = document.getElementById("current")
        var previous = document.getElementById("previous")

        if(window.innerWidth > 900){
            current.style.paddingTop = `${previous.offsetHeight/5}px`
        }else{
            current.style.paddingTop = `${previous.offsetHeight}px`
        }
    },[pathName])

    return(
        <Box style={{padding: '7vw 0 0 0'}}>
            {details?.mainContent && <Box id="previous">
                <Card6 content={details?.mainContent}/>
            </Box>}
            {details?.news && <Box id="current">
                <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: 'center', padding: '0 20vw 3vw 20vw'}}>
                        <Typography style={getTextStyles(template, 'header4', {color: theme.light.info, fontFamily: fontFamily.primary})}>{details.news?.text}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <img alt={details.news?.alt} src={details.news?.image} width="100%"/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{padding: '3vw 20vw'}}>
                        <Typography style={{...getTextStyles(template, 'body', {fontFamily: fontFamily.secondary}), ...{color: theme.light.tertiary}, ...{textAlign: 'center'}}}>{details.news?.subText}</Typography>
                    </Grid>
                </Grid>
            </Box>}
        </Box>
    )
}

export default Details9;