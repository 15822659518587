import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { setEditData } from "../../services/EditingSlice";
import { generateEditPath } from "../../editingComponents/Helpers";

const Card18 = ({content}) => {
    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)

    const dispatch = useDispatch();

    const openEditAbout = (details) => {
        const editingFields = [
            {key: 'content', label: 'About Me'},
        ];
        dispatch(setEditData({
            editType: 'multi-text',
            editTitle: 'Edit About',
            editContent: details,
            editingFields: editingFields,
            regenerateSection: 'ABOUT_ME',
            path: generateEditPath('sections[0].about', editingFields),
            maxWidth: 'lg',
            fullWidth: true
        }))
    }

    return(
        <Box style={{padding: window.innerWidth > 900 ? '7vw 5vw 5vw 18vw' : '7vw 5vw'}}>
            <motion.div
                initial={{x: "300px", opacity: 0}}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: "300px", opacity: 0 }}
                transition={{duration: 0.7}}
            >
                <Box onDoubleClick={() => {
                    openEditAbout(content?.about);
                }}>
                    <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{color: theme.light.primary, fontFamily: fontFamily.secondary, fontSize: 'calc(70px + 2vw)', paddingBottom: '3vw'}}}>01.</Typography>
                    <Box>
                        <Typography variant="h2" style={{...getTextStyles(template, 'header2', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, fontWeight: '900'}}}>About me</Typography>
                        <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{content?.about?.content}</Typography>
                    </Box>
                </Box>
            </motion.div>
        </Box>
    )   
}

export default Card18;