import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux';
import { fontFamilySelector, templateSelector, themeSelector } from '../../services/ThemeSlice';
import { getTextStyles } from '../../style/textStyles';

const Card9 = ({
    data
}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const styles={
        container:{
            position: 'absolute',
            width: '90%',
            background: data?.background,
            zIndex: 10,
            top: `calc(300px + 7vw)`,
        }
    }

    return(
        <Box style={styles.container}>
            {data?.header && <Typography style={{...getTextStyles(template, 'header1', {color: theme.light.primary, fontFamily: fontFamily.primary}), ...{paddingTop: '3vw'}}}>{data.header}</Typography>}
            {data?.content && <Box style={{padding: '1vw 18vw'}}>
                {data.content.split('\n').map((ele)=>(
                    <Typography style={{...getTextStyles(template, 'body', {color: theme.light.info, fontFamily: fontFamily.secondary}), ...{textAlign: data.align}}}>{ele}</Typography>
                ))}
            </Box>}
        </Box>
    )
}

Card9.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired
    })
}

export default Card9;