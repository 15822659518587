import { Box, Grid } from "@mui/material";
import Sections from "../Sections";
import Details11 from "../Details/Details11";

const Template4 = ({templateDetails, screen, refs}) => {
    switch(screen?.selector){
        case "PROJECT_DETAILS": return(<Details11 details={templateDetails[screen?.objectKey]}/>)
        default: return(
            <Box>
                {templateDetails?.sections?.map((ele, i) => (
                    <Grid container key={i} ref={ref => { refs.current[i] = ref }} style={{padding: '70px 0'}}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Sections sectionDetails={ele} />
                        </Grid>
                    </Grid>
                ))}
            </Box>
        )
    }
}

export default Template4;