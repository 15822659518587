import { createSlice } from "@reduxjs/toolkit";
import { get, post } from "../pviewerApi";
import urls from "../urls";
import { setEditData } from "./EditingSlice";
import { setBody, setToastId, setType } from "./NotificationSlice";

const templateSlice = createSlice({
    name: 'templateHandler',
    initialState: {
        template: null,
        templateDetails: {},
        contactDetails: {},
        resources: {},
        title: '',
        sessionKey: '',
        navDetails: {},
        authToken: '',
        showErrorTemplate: false,
        templateLoaded: false,
        documentId: null
    },
    reducers: {
        setTemplate: (state, action) => {
            state.template = action.payload;
        },
        setTemplateDetails: (state, action) => {
            state.templateDetails = action.payload;
        },
        setContactDetails: (state, action) => {
            state.contactDetails = action.payload;
        },
        setResources: (state, action) => {
            state.resources = action.payload;
        },
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setSessionKey: (state, action) => {
            state.sessionKey = action.payload;
        },
        setNavDetails: (state, action) => {
            state.navDetails = action.payload;
        },
        setAuthToken: (state, action) => {
            state.authToken = action.payload;
        },
        setShowErrorTemplate: (state, action) => {
            state.showErrorTemplate = action.payload;
        },
        setTemplateLoaded: (state, action) => {
            state.templateLoaded = action.payload;
        },
        setDocumentId: (state, action) => {
            state.documentId = action.payload;
        }
    }
})

export function getTemplateDetails(){
    return async (dispatch) => {
        get(urls.fecthPortfolio, null, (data) => {
            dispatch(setTemplateDetails(data.templateDetails));
            dispatch(setAuthToken(data.authToken));
            if(data.documentId){
                dispatch(setDocumentId(data.documentId));
            }
            if(data.title){
                dispatch(setTitle(data.title));
            }
            if(data.contactDetails){
                dispatch(setContactDetails(data.contactDetails));
            }
            if(data.resources){
                dispatch(setResources(data.resources));
            }
            if(data.templateDetails.navDetails){
                dispatch(setNavDetails(data.templateDetails.navDetails));
            }
            dispatch(setTemplateLoaded(true));
        }, (error) => {
            dispatch(setShowErrorTemplate(true));
            dispatch(setTemplateLoaded(true));
        })
    }
}

export function createSession(){
    return async (dispatch) => {
        get('https://api-bdc.net/data/ip-geolocation?key=bdc_d9ab4b89723e4ff4a37a457dc1b5136f', null, (data) => {
            post(urls.createSession, null, {
                requestIp: data.ip,
                country: data.country.name,
                countryCode: data.country.isoAlpha2,
                state: data.location.principalSubdivision,
                city: data.location.city,
                latitude: data.latitude,
                longitude: data.longitude
            }, (sessionDetails) => {
                if(sessionDetails.sessionKey !== undefined){
                    dispatch(setSessionKey(sessionDetails.sessionKey))
                    localStorage.setItem("key", sessionDetails.sessionKey)
                }
            })
        }, (error) => {
            console.log(error);
        })
    }
}

export function syncAnalytics() {
    return async (dispatch) => {
        fetch(urls.syncAnalytics, {
            method: 'POST',
            headers: {
                data: JSON.stringify({
                    key: localStorage.getItem("key"),
                    timeSpentOnPage: JSON.parse(localStorage.getItem("timeSpentOnPage")),
                    timeOnApp: JSON.parse(localStorage.getItem("timeOnApp")),
                    userPath: localStorage.getItem("userPath").split(", ").filter(item => item !== ''),
                    scrollDet: JSON.parse(localStorage.getItem("scrollDet"))
                })
            },
            keepalive: true
        }).then(result => {
            console.log("Called and responded")
        })
    }
}

export function regenerateSection(section, documentId) {
    return async (dispatch) => {
        let toastId = 'progress';
        let body = {section, documentId};
        dispatch(setToastId(toastId));
        dispatch(setType('info'));
        dispatch(setBody('Regenerating the section'));
        post(urls.regenerateSection, null, body, () => {
            dispatch(setEditData(null));
            dispatch(getTemplateDetails());
            dispatch(setToastId(toastId));
            dispatch(setType('dismiss'));
            dispatch(setBody('Editing the template'));
        }, () => {
            dispatch(setToastId(toastId));
            dispatch(setType('dismiss'));
            dispatch(setBody('Editing the template'));
            dispatch(setEditData(null));
            dispatch(getTemplateDetails());
        })
    }
}

export function editPortfilio(editPath, editContent, authToken, selector, editSubType) {
    return async (dispatch) => {
        let toastId = 'progress';
        let body = {editPath, editContent, authToken, selector, editSubType};
        dispatch(setToastId(toastId));
        dispatch(setType('info'));
        dispatch(setBody('Editing the template'));
        post(urls.editPortfolio, null, body, () => {
            dispatch(setEditData(null));
            dispatch(getTemplateDetails());
            dispatch(setToastId(toastId));
            dispatch(setType('dismiss'));
            dispatch(setBody('Editing the template'));
        }, () => {
            dispatch(setToastId(toastId));
            dispatch(setType('dismiss'));
            dispatch(setBody('Editing the template'));
            dispatch(setEditData(null));
            dispatch(getTemplateDetails());
        })
    }
}

export function editPictures(editPath, selectedFile, authToken) {
    return async (dispatch) => {
        let formData = new FormData();
        formData.append('file', selectedFile);

        let toastId = 'progress';
        let body = formData;
        dispatch(setToastId(toastId));
        dispatch(setType('info'));
        dispatch(setBody('Editing the template'));
        dispatch(setEditData(null));
        post(urls.editImage, {authToken, editPath}, body, () => {
            dispatch(setEditData(null));
            dispatch(getTemplateDetails());
            dispatch(setToastId(toastId));
            dispatch(setType('dismiss'));
            dispatch(setBody('Editing the template'));
        }, () => {
            dispatch(setToastId(toastId));
            dispatch(setType('dismiss'));
            dispatch(setBody('Editing the template'));
            dispatch(setEditData(null));
            dispatch(getTemplateDetails());
        })
    }
}

export const templateSelector = (state) => state.templateHandler.template;
export const templateDetailsSelector = (state) => state.templateHandler.templateDetails;
export const contactDetailsSelector = (state) => state.templateHandler.contactDetails;
export const resourcesSelector = (state) => state.templateHandler.resources;
export const titleSelector = (state) => state.templateHandler.title;
export const navDetailsSelector = (state) => state.templateHandler.navDetails;
export const authTokenSelector = (state) => state.templateHandler.authToken;
export const showErrorTemplateSelector = (state) => state.templateHandler.showErrorTemplate;
export const templateLoadedSelector = (state) => state.templateHandler.templateLoaded;
export const documentIdSelector = (state) => state.templateHandler.documentId;

export const { setTemplate, setTemplateDetails, setContactDetails,
                setResources, setTitle, setSessionKey, setNavDetails,
                setAuthToken, setShowErrorTemplate, setTemplateLoaded,
                setDocumentId } = templateSlice.actions;

export default templateSlice.reducer;