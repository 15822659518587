import { Box, Button, Grid, Typography } from "@mui/material";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { useDispatch, useSelector } from "react-redux";
import { getTextStyles } from "../../style/textStyles";
import { getButtonStyles } from "../../style/buttonStyles";
import { Facebook, GitHub, LinkedIn, YouTube } from "@mui/icons-material";
import { generateEditPath } from "../../editingComponents/Helpers";
import { setEditData } from "../../services/EditingSlice";

const Contact6 = ({details}) => {
    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)

    const dispatch = useDispatch();

    const styles = {
        button: {
            background: theme.light.primary,
            display: 'flex',
            justifyContent: 'center',
            padding: '2vw 0'
        },
        btnProps: {
            background: theme.light.default,
            color: theme.light.primary
        }
    }

    const openEditEducation = (det, i) => {
        const editingFields = [
            {key: 'college', label: 'College'},
            {key: 'degree', label: 'Degree'},
            {key: 'stream', label: 'Stream'},
            {key: 'GPA', label: 'GPA'}
        ]
        dispatch(setEditData({
            editType: 'multi-text',
            editTitle: 'Edit Education Info',
            editContent: det,
            editingFields: editingFields,
            path:  generateEditPath(`educationInfo.content[${i}]`, editingFields),
            selector: 'contactDetails'
        }))
    }

    const openEdit = (ele, i) => {
        const editingFields = [
            {key: 'text', label: ele.heading},
        ];
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: ele,
            editingFields: editingFields,
            editTitle: `Edit ${ele.heading} Details`,
            selector: 'contactDetails',
            maxWidth: 'xs',
            fullWidth: true,
            path: generateEditPath(`contactInfo[${i}]`, editingFields)
        }))
    }

    const openEditLinks = (det) => {
        const editingFields = [
            {key: 'name', label: 'name'},
            {key: 'linkedInLink', label: 'LinkedIn Link'},
            {key: 'instagramLink', label: 'Instagram'},
            {key: 'youtubeLink', label: 'Youtube Link'},
            {key: 'githubLink', label: 'Github Link'}
        ]
        dispatch(setEditData({
            editType: 'multi-text',
            editTitle: 'Edit External Links',
            editContent: det,
            editingFields: editingFields,
            path:  generateEditPath(``, editingFields),
            selector: 'contactDetails'
        }))
    }

    return(
        <Box style={{display: 'flex', alignItems: window.innerWidth > 900 ? 'flex-end' : 'center', flexDirection: window.innerWidth > 900 ? 'row' : 'column'}}>
            <Box style={window.innerWidth > 900 ? {writingMode: 'vertical-rl', textOrientation: 'sideways', whiteSpace: 'nowrap'} : {}}>
                <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{fontFamily: fontFamily.primary, color: theme.light.primary, fontWeight: '900'}}}>Contact Details</Typography>
            </Box>
            <Box style={{background: theme.light.secondary, width: window.innerWidth > 900 ? 'auto' : '100%'}}>
                <Box style={{background: theme.light.tertiary, padding: '2vw'}} onDoubleClick={() => openEditLinks(details)}>
                    <Typography variant="h3" style={{...getTextStyles(template, 'header3', {}), ...{fontFamily: fontFamily.primary, color: theme.light.primary}}}>{details?.name}</Typography>
                </Box>
                <Box style={{padding: '3vw'}}>
                    {details?.contactInfo?.map((ele, i) => (
                        <Box key={i} style={{padding: '0.2vw', textAlign: 'left'}} onDoubleClick={() => openEdit(ele, i)}>
                            <Typography variant="h5" style={{...getTextStyles(template, 'header5', {}), ...{fontFamily: fontFamily.secondary, color: theme.light.default}}}>{ele?.heading}</Typography>
                            {
                                ele?.heading === "Email" ? <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{fontFamily: fontFamily.primary, color: theme.light.default, cursor: 'pointer'}}} onClick={() => window.location.href = `mailto:${ele?.text}`}>{ele?.text}</Typography> : 
                                (ele?.heading === "Phone" ? <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{fontFamily: fontFamily.primary, color: theme.light.default, cursor: 'pointer'}}} onClick={() => window.location.href = `tel:${ele?.text}`}>{ele?.text}</Typography> : 
                                <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{fontFamily: fontFamily.primary, color: theme.light.default}}}>{ele?.text}</Typography>)
                            }
                        </Box>
                    ))}
                </Box>
                <Box style={styles.button} onDoubleClick={() => openEditLinks(details)}>
                    <Button style={getButtonStyles('floating', styles.btnProps)}><Facebook style={{fontSize: `3vmin`}}></Facebook></Button>
                    <Button style={getButtonStyles('floating', styles.btnProps)}><YouTube style={{fontSize: `3vmin`}}></YouTube></Button>
                    <Button style={getButtonStyles('floating', styles.btnProps)}><LinkedIn style={{fontSize: `3vmin`}}></LinkedIn></Button>
                    <Button style={getButtonStyles('floating', styles.btnProps)}><GitHub style={{fontSize: `3vmin`}}></GitHub></Button>
                </Box>
            </Box>
            <Box style={{background: theme.light.tertiary, flexGrow: 1, width: window.innerWidth > 900 ? 'auto' : '100%'}}>
                <Box style={{background: theme.light.primary, padding: '2vw'}}>
                    <Typography variant="h3" style={{...getTextStyles(template, 'header3', {}), ...{fontFamily: fontFamily.primary, color: theme.light.default}}}>{details?.educationInfo?.title}</Typography>
                </Box>
                <Grid container>
                    {details?.educationInfo?.content?.map((ele, i) => (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                            <Box style={{padding: '3vw'}} onDoubleClick={() => openEditEducation(ele, i)}>
                                <Typography variant="h4" style={{...getTextStyles(template, 'header4', {}), ...{fontFamily: fontFamily.secondary, color: theme.light.primary}}}>{ele?.college}</Typography>
                                <Typography variant="h5" style={{...getTextStyles(template, 'header5', {}), ...{fontFamily: fontFamily.primary, color: theme.light.primary}}}>{ele?.degree}</Typography>
                                <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{fontFamily: fontFamily.primary, color: theme.light.primary}}}>{ele?.stream}</Typography>
                                <Typography variant="h6" style={{...getTextStyles(template, 'header6', {}), ...{fontFamily: fontFamily.primary, color: theme.light.primary}}}>{ele?.GPA? `GPA ${ele.GPA}` : ''}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}

export default Contact6;