const textStyles = {
    template1 : {
        header1: {
            fontSize: 'calc(50px + 1vw)',
            textAlign: 'center',
        },
        header2: {
            fontSize: 'calc(25px + 1vw)'
        },
        header3: {
            fontSize: 'calc(20px + 0.5vw)',
        },
        header4: {
            fontSize: 'calc(15px + 0.5vw)',
        },
        header5: {
            fontSize: 'calc(10px + 0.5vw)',
        },
        header6: {
            opacity: '0.6',
            fontSize: 'calc(6px + 0.5vw)',
            letterSpacing: '2px'
        },
        body: {
            fontSize: 'calc(8px + 0.5vw)',
            fontWeight: '5px',
            lineHeight: '1.5',
            textAlign: 'center',
            padding: '2vmin 0'
        }
    },
    template2: {
        header1: {
            fontSize: 'calc(50px + 1vw)',
            textAlign: 'center',
        },
        header2: {
            fontSize: 'calc(25px + 1vw)'
        },
        header3: {
            fontSize: 'calc(20px + 1vw)',
        },
        header4: {
            fontSize: 'calc(15px + 1vw)',
        },
        header5: {
            fontSize: 'calc(10px + 1vw)',
        },
        header6: {
            opacity: '0.6',
            fontSize: 'calc(5px + 1vw)',
            letterSpacing: '2px'
        },
        body: {
            fontSize: 'calc(8px + 0.5vw)',
            fontWeight: '5px',
            lineHeight: '1.5',
            textAlign: 'center',
            padding: '2vmin 0'
        }
    },
    template3: {
        header1: {
            fontSize: 'calc(50px + 1vw)',
            textAlign: 'center',
        },
        header2: {
            fontSize: 'calc(25px + 1vw)'
        },
        header3: {
            fontSize: 'calc(20px + 1vw)',
        },
        header4: {
            fontSize: 'calc(15px + 1vw)',
        },
        header5: {
            fontSize: 'calc(10px + 1vw)',
        },
        header6: {
            opacity: '0.6',
            fontSize: 'calc(5px + 1vw)',
            letterSpacing: '2px'
        },
        body: {
            fontSize: 'calc(8px + 0.5vw)',
            fontWeight: '5px',
            lineHeight: '1.5',
            textAlign: 'center',
            padding: '2vmin 0'
        }
    },
    template4: {
        header1: {
            fontSize: 'calc(50px + 1vw)',
            textAlign: 'center',
        },
        header2: {
            fontSize: 'calc(25px + 1vw)'
        },
        header3: {
            fontSize: 'calc(20px + 1vw)',
        },
        header4: {
            fontSize: 'calc(15px + 1vw)',
        },
        header5: {
            fontSize: 'calc(12px + 0.5vw)',
        },
        header6: {
            opacity: '0.6',
            fontSize: 'calc(7px + 0.5vw)',
            letterSpacing: '2px'
        },
        body: {
            fontSize: 'calc(6px + 0.5vw)',
            lineHeight: '2',
        }
    },
    template5: {
        header1: {
            fontSize: 'calc(50px + 1vw)',
        },
        header2: {
            fontSize: 'calc(22px + 1vw)'
        },
        header3: {
            fontSize: 'calc(15px + 0.5vw)',
        },
        header4: {
            fontSize: 'calc(12px + 0.5vw)',
        },
        header5: {
            fontSize: 'calc(10px + 0.5vw)',
        },
        header6: {
            opacity: '0.6',
            fontSize: 'calc(6px + 0.5vw)',
            letterSpacing: '2px'
        },
        body: {
            fontSize: 'calc(6px + 0.5vw)',
            fontWeight: '5px',
            lineHeight: '1.7'
        } 
    },
    template6: {
        header1: {
            fontSize: 'calc(50px + 1vw)',
        },
        header2: {
            fontSize: 'calc(25px + 1vw)'
        },
        header3: {
            fontSize: 'calc(20px + 1vw)',
        },
        header4: {
            fontSize: 'calc(20px + 0.5vw)',
            letterSpacing: '2px'
        },
        header5: {
            fontSize: 'calc(13px + 0.5vw)',
            letterSpacing: '3px'
        },
        header6: {
            fontSize: 'calc(8px + 0.5vw)',
            letterSpacing: '4px'
        },
        body: {
            fontSize: 'calc(7px + 0.5vw)',
            lineHeight: '1.7'
        } 
    }
}

export const getTextStyles = (template, variant, customProps) => {
    textStyles[template][variant] = {...textStyles[template][variant], ...customProps }
    return textStyles[template][variant]
}