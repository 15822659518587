import { Box } from "@mui/system";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { fontFamilySelector } from "../../services/ThemeSlice";
import Card6 from "../Card/Card6";
import { Grid, Typography } from "@mui/material";
import { pathNameSelector } from "../../services/PathSlice";
import CustomForm from "../CustomForm/CustomForm";

const Details7 = ({details}) => {
    const fontFamily = useSelector(fontFamilySelector)

    const pathName = useSelector(pathNameSelector)

    const styles = {
        header: {
            paddingTop: '3vw',
            fontFamily: fontFamily.secondary
        }
    }

    useEffect(()=>{
        var current = document.getElementById("current")
        var previous = document.getElementById("previous")

        if(window.innerWidth > 900){
            current.style.paddingTop = `${previous.offsetHeight/5}px`
        }else{
            current.style.paddingTop = `${previous.offsetHeight}px`
        }
    },[pathName])

    return(
        <Box style={{padding: '3vw 0 0 0'}}>
            {details?.mainContent && <Box id="previous">
                <Card6 content={details?.mainContent}/>
            </Box>}
            {details?.content && <Box style={{padding: '0 15vw 10vw 15vw'}} id="current">
                {details.content.map((ele)=>(
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h4" style={styles.header}>{ele?.header}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            {<CustomForm formDetails={ele.form? ele.form : ele.subHeader} btnText={ele?.buttonText}/>}  
                        </Grid>
                    </Grid>
                ))}
            </Box>}
        </Box>
    )
}

export default Details7;