import { Box } from "@mui/system";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Card6 from "../Card/Card6";
import { Grid } from "@mui/material";
import { pathNameSelector } from "../../services/PathSlice";

const Details8 = ({details}) => {
    const pathName = useSelector(pathNameSelector)

    useEffect(()=>{
        var current = document.getElementById("current")
        var previous = document.getElementById("previous")

        var noOfChars = details?.mainContent?.content.length

        if(100 < noOfChars && noOfChars < 1000){
            current.style.paddingTop = `700px`
        }else if(1000 < noOfChars && noOfChars < 1500){
            current.style.paddingTop = `900px`
        }else if(1500 < noOfChars && noOfChars < 2300){
            current.style.paddingTop = `1400px`
        }else if(2300 < noOfChars && noOfChars < 3000){
            previous.style.paddingBottom = `1800px`
        }else if(3000 < noOfChars && noOfChars < 4000){
            previous.style.paddingBottom = `2000px`
        }
    },[pathName, details?.mainContent?.content.length])

    return(
        <Box style={{padding: '5vw 0 0 0'}}>
            {details?.mainContent && <Box id="previous">
                <Card6 content={details?.mainContent}/>
            </Box>}

            {details?.images && <Box id="current" style={{padding: '0 3vw'}}>
                <Grid container spacing={5}>
                    {details?.images.map((ele)=>(
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <img alt={ele.alt} src={ele.source} width='100%'/>
                        </Grid> 
                    ))}
                </Grid>
            </Box>}
        </Box>
    )
}

export default Details8;