import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom";

const Card5 = (
    {
        data,
        position
    }
) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const navigate = useNavigate()

    const styles = {
        banner: {
            background: theme.light.default,
            width: window.innerWidth > 900 ? '55%' : '85%',
            borderTop: `5px solid ${theme.light.info}`,
            borderBottom: `5px solid ${theme.light.info}`,
            textAlign: 'left',
            zIndex: 10,
            position: 'absolute',
            padding: '2vw'
        },
        button: {
            fontFamily: fontFamily.primary, 
            color: theme.light.info, 
            fontSize: `calc(3px + 1vw)`, 
            textTransform: 'capitalize'
        }
    }

    const clickHandler = (link) => {
        navigate(link);
    }
    
    return(
        <Box style={window.innerWidth > 900 ? {...styles.banner, ...position} : {...styles.banner, ...{bottom: 'calc(-40px + 2vw)'}}} id="section">
            {data?.header && <Typography style={getTextStyles(template, 'header5', {color: theme.light.info, fontFamily: fontFamily.primary})}>{data.header}</Typography>}
            {data?.content && <Typography style={getTextStyles(template, 'body', {color: theme.light.info, fontFamily: fontFamily.secondary, textAlign: 'left'})}>{data.content}</Typography>}
            <Button style={styles.button} onClick={() => data?.link && clickHandler(data.link)}>Read More</Button>
        </Box>
    )
}

Card5.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
    }),
    position: PropTypes.object.isRequired
}

export default Card5;