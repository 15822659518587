const buttonStyles = {
    raised: {
        boxShadow: `0px 24px 42px -8px rgba(40, 75, 99, 0.55)`,
        fontSize: '2vmin',
        padding: '1vmin 3vmin',
        borderRadius: '8px'
    },
    rounded: {
        boxShadow: `0px 24px 42px -8px rgba(60, 110, 113, 0.55)`,
        fontSize: '2vmin',
        padding: '1vmin 3vmin',
        borderRadius: '50px'
    },
    ghost: {
        backgroundColor: 'transperent',
        borderWidth: '2px',
        borderStyle: 'solid',
        boxShadow: `rgb(255, 255, 255) -10px -10px 0px -2px, rgb(40, 75, 99) -10px -10px`,
        fontSize: '2vmin',
        padding: '1vmin 3vmin',
        borderRadius: '5px'
    },
    floating: {
        // boxShadow: `inset 0 0 0 0 ${theme.light.secondary}, 0px 24px 30px -2px rgba(0, 0, 0, 0.2)`,
        transition: 'all .5s ease-out',
        borderWidth: '2px',
        borderStyle: 'solid',
        padding: '0.5vmin',
        minWidth: '2vmin',
        minHeight: '2vmin',
        margin: '0 2vmin',
        borderRadius: '100%'
    },
    floatingHover: {
        // boxShadow: `inset 0 100px 0 0 ${theme.light.tertiary}, 0px 24px 30px -2px rgba(0, 0, 0, 0.2)`,
        transition: 'all .5s ease-in',
        borderWidth: '2px',
        borderStyle: 'solid',
        margin: '2vw',
        padding: '10px',
        minWidth: '10px',
        minHeight: '10px',
        borderRadius: '100%'
    },
    press: {
        width: '100%',
        broderRadius: '5px',
        boxShadow: '9px 9px rgb(91, 76, 106)',
        padding: '1vw',
        fontSize: 'calc(15px + 1vw)',
        transition: 'all ease 0.2s'
    },
    pressHover: {
        width: '100%',
        broderRadius: '5px',
        boxShadow: '1px 1px rgb(91, 76, 106)',
        padding: '1vw',
        fontSize: 'calc(15px + 1vw)',
        transition: 'all ease 0.2s'
    },
    square: {
        minWidth: '40px',
        height: '40px',
        margin: '10px',
        padding: 0,
        borderRadius: '10px',
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
    }
}
export const getButtonStyles = (variant, customProps) => {
    buttonStyles[variant] = {...buttonStyles[variant], ...customProps }
    return buttonStyles[variant]
}