import { Box, Grid, Typography } from "@mui/material";
import { getTextStyles } from "../../style/textStyles";
import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";

const Details10 = ({details}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const styles = {
        container: {
            background: theme.light.secondary,
            padding: '10vw'
        }
    }

    return(
        <Box style={styles.container}>
            <Grid container style={{display:'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h1" style={getTextStyles(template, 'header1', {color: theme.light.primary, fontFamily: fontFamily.primary})}>{details?.header}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{padding: '3vw 0'}}>
                    <Typography variant="body" style={getTextStyles(template, 'body', {color: theme.light.info, fontFamily: fontFamily.secondary, textAlign: 'left'})}>{details?.text}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} style={{padding: '3vw 0'}}>
                    <img alt={details?.alt} src={details?.image} width="100%"/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{padding: '3vw 0'}}>
                    <Typography variant="body" style={getTextStyles(template, 'body', {color: theme.light.info, fontFamily: fontFamily.secondary, textAlign: 'left'})}>{details?.subText}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Details10;