import { useDispatch, useSelector } from "react-redux"
import { arrayContentSelector, arrayIndexSelector, deletePathSelector, editContentSelector, editSubTypeSelector, editTitleSelector, editTypeSelector, editingFieldsSelector, fullWidthSelector, maxWidthSelector, pathSelector, regenerateSectionSelector, selectorSelector, setEditData } from "../services/EditingSlice"
import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, TextField } from "@mui/material";
import { authTokenSelector, documentIdSelector, editPictures, editPortfilio, regenerateSection } from "../services/TemplateSlice";
import { useLocation } from "react-router-dom";
import FileUpload from "./FileUpload";

const TextEditing = () => {
    const documentId = useSelector(documentIdSelector);
    const editType = useSelector(editTypeSelector);
    const editContent = useSelector(editContentSelector);
    const editTitle = useSelector(editTitleSelector);
    const editPath = useSelector(pathSelector);
    const editingFields = useSelector(editingFieldsSelector);
    const authToken = useSelector(authTokenSelector);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const maxWidth = useSelector(maxWidthSelector);
    const fullWidth = useSelector(fullWidthSelector);
    const selector = useSelector(selectorSelector);
    const editSubType = useSelector(editSubTypeSelector);
    const arrayContent = useSelector(arrayContentSelector);
    const arrayIndex = useSelector(arrayIndexSelector);
    const deletePath = useSelector(deletePathSelector);
    const section = useSelector(regenerateSectionSelector);

    const dispatch = useDispatch();

    const shiftElementsByPosition = (jsonArray, positionToMove, newPosition) => {
        if (positionToMove <= 0 || positionToMove > jsonArray.length) {
          console.error("Invalid positionToMove value.");
          return jsonArray;
        }
      
        if (newPosition <= 0 || newPosition > jsonArray.length) {
          console.error("Invalid newPosition value.");
          return jsonArray;
        }
      
        const shiftedArray = jsonArray.slice(); // Create a copy of the original array
        const elementToMove = shiftedArray.splice(positionToMove - 1, 1)[0]; // Remove the element from its original position
        shiftedArray.splice(newPosition - 1, 0, elementToMove); // Insert the element at the new position
      
        return shiftedArray;
    }
            

    return(
        <React.Fragment>
            <Dialog fullWidth maxWidth='lg' open={editType === 'text' && authToken === queryParams.get('authToken') 
                                                 && Boolean(authToken) && Boolean(queryParams.get('authToken'))} 
                                                 onClose={() => dispatch(setEditData(null))}>
                <DialogTitle>{editTitle}</DialogTitle>
                <DialogContent>
                    <TextField 
                        autoFocus
                        id="editContent"
                        fullWidth
                        multiline
                        maxRows={20}
                        value={editContent}
                        onChange={(e) => dispatch(setEditData({
                            editType: editType,
                            editContent: e.target.value,
                            editTitle: editTitle,
                            path: editPath,
                            selector: selector,
                            regenerateSection: section
                        }))}
                    />
                </DialogContent>
                <DialogActions>
                    {section && documentId && <Button onClick={() => {
                        dispatch(setEditData(null));
                        dispatch(regenerateSection(section, documentId));
                    }}>Regenarate</Button>}
                    <Button onClick={() => {
                        dispatch(setEditData(null));
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        dispatch(editPortfilio(editPath, editContent, queryParams.get('authToken'), selector));
                    }}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog maxWidth={maxWidth} fullWidth={fullWidth} open={editType === 'multi-text' && Boolean(authToken) && authToken === queryParams.get('authToken')} onClose={() => dispatch(setEditData(null))}>
                <DialogTitle>{editTitle}</DialogTitle>
                <DialogContent>
                    {editingFields?.map((item, i) => (
                        <TextField 
                        id={"editContent" + i}
                        fullWidth
                        multiline
                        maxRows={20}
                        label={item.label}
                        style={{margin: 5}}
                        value={editContent?.[item.key]}
                        onChange={(e) => dispatch(setEditData({
                            editType: editType,
                            editContent: {...editContent, [item.key]: e.target.value},
                            editTitle: editTitle,
                            editingFields: editingFields,
                            path: editPath,
                            maxWidth: maxWidth,
                            fullWidth: fullWidth,
                            selector: selector,
                            editSubType: editSubType,
                            arrayContent: arrayContent,
                            regenerateSection: section
                        }))}
                    />
                    ))}
                </DialogContent>
                <DialogActions>
                    {section && documentId && <Button onClick={() => {
                        dispatch(setEditData(null));
                        dispatch(regenerateSection(section, documentId));
                    }}>Regenarate</Button>}
                    <Hidden xlDown={(typeof(arrayIndex) === 'number' && arrayIndex === 0) || typeof(arrayIndex) === 'undefined'}>
                        <Button onClick={() => {
                            dispatch(editPortfilio(deletePath, shiftElementsByPosition(arrayContent, arrayIndex + 1, arrayIndex), queryParams.get('authToken'), selector, 'array'));
                        }}>Move Up</Button>
                    </Hidden>
                    <Hidden xlDown={(typeof(arrayIndex) === 'number' && arrayIndex === arrayContent.length - 1) || typeof(arrayIndex) === 'undefined'}>
                        <Button onClick={() => {
                            dispatch(editPortfilio(deletePath, shiftElementsByPosition(arrayContent, arrayIndex + 1, arrayIndex + 2), queryParams.get('authToken'), selector, 'array'));
                        }}>Move Down</Button>
                    </Hidden>
                    <Button onClick={() => {
                        dispatch(setEditData(null));
                    }}>Cancel</Button>
                    {typeof(arrayIndex) === 'number' && <Button onClick={() => {
                        let finalData = [...arrayContent].map(item => ({...item, order: item.order ? item.order : 0}));
                        finalData.splice(arrayIndex, 1);
                        dispatch(editPortfilio(deletePath, finalData, queryParams.get('authToken'), selector, 'array'));
                    }}>Delete</Button>}
                    <Button onClick={() => {
                        dispatch(editPortfilio(editPath, editSubType === 'array' ? [...arrayContent, editContent] : editContent, queryParams.get('authToken'), selector, editSubType === undefined ? '' : editSubType));
                    }}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth maxWidth='lg' open={editType === 'image' && authToken === queryParams.get('authToken')
                                                && Boolean(authToken) && Boolean(queryParams.get('authToken'))} onClose={() => dispatch(setEditData(null))}>
                <DialogTitle>{editTitle}</DialogTitle>
                <DialogContent>
                    <FileUpload fileDropped={(file) => dispatch(editPictures(editPath, file, queryParams.get('authToken')))}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        dispatch(setEditData(null));
                    }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default TextEditing;