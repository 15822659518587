import { Box, Button, Grid, Typography } from "@mui/material";
import { getTextStyles } from "../../style/textStyles";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { PropTypes } from 'prop-types';
import { setEditData } from "../../services/EditingSlice";
import { generateEditPath } from "../../editingComponents/Helpers";

const Card23 = ({content}) => {
    const template = useSelector(templateSelector);
    const theme = useSelector(themeSelector);
    const fontFamily = useSelector(fontFamilySelector);
    const dispatch = useDispatch();

    const styles = {
        bullet: {
            minWidth: '20px',
            height: '20px',
            padding: 0,
            borderRadius: '50%'
        }
    }

    const openEdit = (ele, i) => {
        const editingFields = [
            {key: 'skill', label: 'Skill'},
            {key: 'level', label: 'Level'}
        ];
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: ele,
            editingFields: editingFields,
            editTitle: 'Edit Skill Details',
            path: generateEditPath(`sections[0].skills.content[${i}]`, editingFields)
        }))
    }

    return(
        <Grid container>
            {content && <Grid item xs={12} sm={12} md={12} lg={12} style={{background: theme.light.tertiary}}>
                <Box style={{padding: '1vw'}}>
                    <Typography variant="h1" style={{...getTextStyles(template, 'header1', {}), ...{color: theme.light.primary, fontFamily: fontFamily.secondary}}}>{content?.title}</Typography>
                    <Box style={{padding: '2vw', display: 'flex', flexWrap: 'wrap', flexDirection: window.innerWidth > 400 ? 'row' : 'column'}}>
                        {content?.content?.map((ele, i) => (
                            <Box style={{padding: '1vw', display: 'flex'}} key={i} onDoubleClick={() => openEdit(ele, i)}>
                                <Button style={{...styles.bullet, ...{background: `linear-gradient(90deg, ${theme.light.primary} ${ele?.level}, ${theme.light.secondary} calc(100% - ${ele?.level}))`}}} />
                                <Typography variant="h5" style={{...getTextStyles(template, 'header5', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary, padding: '0 1vw'}}}>{ele?.skill}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Grid>}
        </Grid>
    )
}

Card23.propTypes = {
    content: PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.array.isRequired
    })
}

export default Card23;