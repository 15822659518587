import Card5 from "./Card5";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Card9 from "./Card9";

const Card6 = ({ content }) => {
    const styles={
        container: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 'auto'
        },
        image: {
            objectFit: `cover`,
            objectPosition: `center`,
            width: '100%',
            minWidth: '300px'
        }
    }

    const [position, setPosition] = useState({})

    useEffect(()=>{        
        switch(content.position){
            case 'top': 
                setPosition({top: `calc(-40px + 2vw)`})
            break;
            case 'top-left':
                setPosition({top: `calc(-40px + 2vw)`, left: `calc(20px + 1vw)`})
            break;
            case 'top-right':
                setPosition({top: `calc(-40px + 2vw)`, right: `calc(20px + 1vw)`})
            break;
            case 'bottom': 
                setPosition({top: `${content.height - 100}px`})
            break;
            case 'bottom-left':
                setPosition({bottom: `calc(-40px + 2vw)`, left: `calc(20px + 1vw)`})
            break;
            case 'bottom-right':
                setPosition({bottom: `calc(-40px + 2vw)`, right: `calc(20px + 1vw)`})
            break;
            default:
                setPosition({top: `calc(-40px + 2vw)`})
            break;
        }
    }, [content.height, content.position])

    return(
        <Box style={styles.container}>
            <img alt={content.alt} src={content.image} style={styles.image} height={window.innerWidth > 900 ?content.height : `${320}px`}/>
            {content.position ? 
                <Card5 data={content} position={position}/> : 
                <Card9 data={content}/>
            }
        </Box>
    )
}

Card6.propTypes = {
    content: PropTypes.shape({
        header: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        height: PropTypes.number.isRequired,
        link: PropTypes.string,
        position: PropTypes.string,
        background: PropTypes.string,
        align: PropTypes.string.isRequired
    })
}

export default Card6;