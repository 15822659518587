import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { pathNameSelector } from "../../services/PathSlice";
import Card6 from "../Card/Card6";

const Details3 = ({details}) => {

    const pathName = useSelector(pathNameSelector)

    useEffect(()=>{
        var current = document.getElementById("current")
        var previous = document.getElementById("previous")

        if(current !== null){
            if(window.innerWidth > 900){
                current.style.paddingTop = `${previous?.offsetHeight/2}px`
            }else{
                current.style.paddingTop = `${previous?.offsetHeight}px`
            }  
        }
    },[pathName])

    return(
        <Box style={{padding: '3vw 0 0 0'}}>
            {details?.mainContent && <Box id="previous">
                <Card6 content={details.mainContent}/>
            </Box>}
            {details?.stakeHolders && <Box style={{padding: '0 15vw'}} id="current">
                <Grid container spacing={5}>
                    {details?.stakeHolders.map((stakeHolder, key)=>(
                        <Grid item xs={12} sm={12} md={6} lg={6} id={key} style={{paddingBottom: '25vw'}}>
                            <Card6 content={stakeHolder}/>
                        </Grid>
                    ))} 
                </Grid>
            </Box>}
        </Box>
    )
}

export default Details3;