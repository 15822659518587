import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { PropTypes } from 'prop-types';
import { getTextStyles } from "../../style/textStyles";

const Card24 = ({content, draggable, onDoubleClick, onDragEnd, onDragOver}) => {
    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)

    const styles = {
        line: {
            width: '3px',
            background: theme.light.tertiary,
            height: '100%'
        },
        box: {
            clipPath: `polygon(0% 0%, 0% 100%, 90% 100%, 100% 50%, 90% 0%)`,
            background: theme.light.primary,
            padding: '1vw'
        }
    }

    return(
        <Box>
                {content?.experiences?.map((ele, i) => (
                    <Grid container
                        draggable={draggable}
                        style={{cursor: draggable ? 'move' : 'default'}}
                        onDoubleClick={() => onDoubleClick(ele, i)}
                        onDragEnd={() => onDragEnd(ele, i)}
                        onDragOver={() => onDragOver(ele, i)}
                    >
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Box style={styles.box}>
                                <Typography varaint="h5" style={{...getTextStyles(template, 'header5', {}), ...{color: theme.light.default, fontFamily: fontFamily.secondary}}}>{ele?.year}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} lg={1}>
                            <Box style={styles.line}></Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Box style={{padding: '0.8vw 0 2vw 0'}}>
                                <Typography varaint="h4" style={{...getTextStyles(template, 'header4', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary}}}>{ele?.company}</Typography>
                                <Typography varaint="h6" style={{...getTextStyles(template, 'header6', {}), ...{color: theme.light.secondary, fontFamily: fontFamily.primary}}}>{ele?.role}</Typography>
                                <Typography varaint="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{ele?.description}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    
                ))}
        </Box>
    ) 
}

Card24.propTypes = {
    content: PropTypes.shape({
        experiences: PropTypes.array.isRequired
    })
}

export default Card24;