import { Box, Grid, Typography } from "@mui/material";
import Card6 from "../Card/Card6";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { useSelector } from "react-redux";
import { getTextStyles } from "../../style/textStyles";
import Card7 from "../Card/Card7";
import { useEffect } from "react";
import { pathNameSelector } from "../../services/PathSlice";

const About3 = ({aboutDetails}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const pathName = useSelector(pathNameSelector)

    useEffect(()=>{
        var current = document.getElementById("current")
        var previous = document.getElementById("previous")

        if(window.innerWidth > 900){
            current.style.paddingTop = `${previous?.offsetHeight + 150}px`
        }else{
            current.style.paddingTop = `${previous?.offsetHeight + 400}px`
        }  
    },[pathName])

    return(
        <Box style={{paddingTop:'5vw'}}>
            {aboutDetails?.mainContent && <Box id="previous">
                <Card6 content={aboutDetails.mainContent}/>
            </Box>}
            {aboutDetails?.sections && aboutDetails?.sections.map(section => (
                <Grid container id="current">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography style={getTextStyles(template, 'header1', {color: theme.light.primary, fontFamily: fontFamily.primary})}>{section?.sectionTitle}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card7 content={section?.sectionContent}/>
                    </Grid>
                </Grid>
            ))}
            <Grid container style={{width: '100%', padding: '5vw'}}>
                <Box style={{display:'grid', gridTemplateColumns: 'repeat(12, 1fr)', gridTemplateRows: 'repeat(6, 5vw)', gap: '3vw'}}>
                    <Box style={{gridColumnStart: 1, gridColumnEnd: 5, gridRowStart: 1, gridRowEnd: 3}}>
                        <img alt={aboutDetails?.gallery[0]?.alt} src={aboutDetails?.gallery[0]?.src} width="100%" height="100%" style={{objectFit: 'cover'}}/>
                    </Box>
                    <Box style={{gridColumnStart: 5, gridColumnEnd: 8, gridRowStart: 1, gridRowEnd: 3}}>
                        <img alt={aboutDetails?.gallery[1]?.alt} src={aboutDetails?.gallery[1]?.src} width="100%" height="100%" style={{objectFit: 'cover'}}/>
                    </Box>
                    <Box style={{gridColumnStart: 8, gridColumnEnd: 13, gridRowStart: 1, gridRowEnd: 4}}>
                        <img alt={aboutDetails?.gallery[2]?.alt} src={aboutDetails?.gallery[2]?.src} width="100%" height="100%" style={{objectFit: 'cover'}}/>
                    </Box>
                    <Box style={{gridColumnStart: 1, gridColumnEnd: 8, gridRowStart: 3, gridRowEnd: 7}}>
                        <img alt={aboutDetails?.gallery[3]?.alt} src={aboutDetails?.gallery[3]?.src} width="100%" height="100%" style={{objectFit: 'cover'}}/>
                    </Box>
                    <Box style={{gridColumnStart: 8, gridColumnEnd: 13, gridRowStart: 4, gridRowEnd: 7}}>
                        <img alt={aboutDetails?.gallery[4]?.alt} src={aboutDetails?.gallery[4]?.src} width="100%" height="100%" style={{objectFit: 'cover'}}/>
                    </Box>
                </Box>
            </Grid>
        </Box>
    )
}

export default About3;