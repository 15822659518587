import { createSlice } from "@reduxjs/toolkit"

export const pathSlice = createSlice({
    name: 'pathHandler',
    initialState: {
        pathName: ''
    },
    reducers: {
        setPathNameReducer: (state, action) => {
            state.pathName = action.payload
        }
    }
})

export function setPathName(name) {
    return (dispatch) => {
        dispatch(setPathNameReducer(name))
    }
}

export const { setPathNameReducer } = pathSlice.actions;
export const pathNameSelector = (state) => state.pathHandler.pathName;
export default pathSlice.reducer;