import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import { bodySelector, setBody, setToastId, setType, toastIdSelector, typeSelector } from "../services/NotificationSlice"
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

const Notifications = () => {

    const dispatch = useDispatch();
    const body = useSelector(bodySelector);
    const type = useSelector(typeSelector);
    const toastId = useSelector(toastIdSelector);

    useEffect(() => {
        if(type && body){
            switch(type) {
                case 'error':
                    toast.error(body, {
                        autoClose: 2000,
                        hideProgressBar: true,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        toastId: toastId
                    })
                    break;
                case 'info':
                    toast.info(body, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        isLoading: true,
                        toastId: toastId
                    })
                    break;
                case 'dismiss':
                    toast.dismiss(toastId);
                    break;
                default:
                    toast.error(body, {
                        autoClose: 2000,
                        hideProgressBar: true,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        toastId: toastId
                    })
            }
            if(body !== ''){
                dispatch(setBody(''));
                dispatch(setType(''));
                dispatch(setToastId(''));
            }
        }

    }, [body, type, toastId, dispatch])

    return(
        <> 
            <ToastContainer />
        </>
    )
}

export default Notifications;