import { Box, Grid, Typography } from "@mui/material";
import Card15 from "./Card15";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { PropTypes } from 'prop-types';
import { resourcesSelector } from "../../services/TemplateSlice";
import { setEditData } from "../../services/EditingSlice";

const Card14 = ({content, index, doubleClick, draggable, onDragEnd, onDragOver}) => {
    const template = useSelector(templateSelector);
    const theme = useSelector(themeSelector);
    const fontFamily = useSelector(fontFamilySelector);
    const resources = useSelector(resourcesSelector);

    const dispatch = useDispatch();

    const styles = {
        wrap: {
            padding: '2vw',
            borderRadius: '10px'
        }
    }

    return(
        <Box boxShadow={2} 
            style={{...styles.wrap, cursor: draggable ? 'move' : 'default'}} 
            draggable={draggable}
            onDragEnd={(event) => onDragEnd()}
            onDragOver={(event) => onDragOver()}>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={4} lg={4} style={{display: 'flex', justifyContent: 'center'}} onDoubleClick={() => {
                    dispatch(setEditData({
                        editType: 'image',
                        editContent: content.image,
                        editTitle: 'Edit About Me',
                        path: content.image
                    }))
                }}>
                    <Card15 image={resources[content.image]}/>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} onDoubleClick={() => {
                        if(typeof(doubleClick) === 'function'){
                            doubleClick();
                        }
                    }}>
                    <Typography variant="h3" style={{...getTextStyles(template, 'header3', {}), ...{color: theme.light.tertiary, fontFamily: fontFamily.primary}}}>{content.name}</Typography>
                    <Typography variant="h6" style={{...getTextStyles(template, 'header6', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary}}}>{content.technologies}</Typography>
                    <Box style={{paddingTop: '1vw'}}>
                        <Typography variant="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary}}}>{content.description}</Typography> 
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

Card14.propTypes = {
    content : PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        technologies: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired
    }),
}

export default Card14;