import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { Box, Grid, Typography } from "@mui/material";
import { PropTypes } from 'prop-types';
import { resourcesSelector } from "../../services/TemplateSlice";
import { setEditData } from "../../services/EditingSlice";

const Card25 = ({content}) => {
    const template = useSelector(templateSelector)
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const resources = useSelector(resourcesSelector)

    const dispatch = useDispatch();

    const styles = {
        container: {
            display: 'flex', 
            flexDirection: window.innerWidth > 900 ? 'row' : 'column', 
            justifyContent: 'center', 
            alignItems: 'center'
        },
        grid: {
            flexGrow: 1, 
            flexShrink: 1, 
            flexBasis: 0, 
            margin: '2vw'
        },
        intro: {
            background: theme.light.tertiary,
            padding: '2vw 3vw',
            minHeight: window.innerWidth > 900 ? '150px' : 0
        },
        imageWrap: {
            width: '100%',
            minHeight: '150px'
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'scale-down',
            objectPosition: 'center'
        },
        technologies: {
            width: '100%',
            background: theme.light.primary,
            padding: '2vw 1vw'
        },
        description: {
            background: `linear-gradient(45deg, ${theme.light.tertiary}, ${theme.light.secondary})`,
            padding: '2vw',
            overflow: 'auto'
        }
    }

    const openEditImage = (img) => {
        dispatch(setEditData({
            editType: 'image',
            editContent: img,
            editTitle: 'Edit Picture',
            path: img
        }))
    }

    return(
        <Box style={styles.container}>
            <Box style={styles.grid}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box style={styles.intro}>
                            <Typography varaint="h3" style={{...getTextStyles(template, 'header3', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary}}}>{content?.name}</Typography>
                            <Typography varaint="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.info, fontFamily: fontFamily.primary}}}>{content?.intro}</Typography>
                        </Box>
                    </Grid> 
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box style={styles.imageWrap} onDoubleClick={(event) => {
                            event.stopPropagation();
                            openEditImage(content?.image);
                        }}>
                            <img src={resources[content?.image]} alt="project" style={styles.image} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box style={styles.technologies}>
                            <Typography varaint="h5" style={{...getTextStyles(template, 'header5', {}), ...{color: theme.light.default, fontFamily: fontFamily.secondary}}}>{content?.technologies}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box style={styles.grid}>
                <Box style={styles.description}>
                <Typography varaint="h3" style={{...getTextStyles(template, 'header3', {}), ...{color: theme.light.primary, fontFamily: fontFamily.secondary}}}>More on the project</Typography>
                    <Typography varaint="body" style={{...getTextStyles(template, 'body', {}), ...{color: theme.light.primary, fontFamily: fontFamily.primary}}}>{content?.description}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

Card25.propTypes = {
    content: PropTypes.shape({
        name: PropTypes.string.isRequired,
        intro: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        technologies: PropTypes.string.isRequired
    })
}

export default Card25;