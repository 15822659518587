import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { getButtonStyles } from "../../style/buttonStyles";
import { getTextStyles } from "../../style/textStyles";
import { LinkedIn } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";
import { Email } from "@mui/icons-material";
import { GitHub } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { setEditData } from "../../services/EditingSlice";

const Contact1 = ({details}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const dispatch = useDispatch();

    const styles = {
        container: {
            padding: '5vmin',
            background: theme.light.info,
        },
        button:{
            padding: '3vmin'
        },
        education: {
            padding: '7vmin 3vmin 3vmin 3vmin',
            display: 'flex',
            justifyContent: 'center'
        },
        btnProps: {
            backgroundColor: theme.light.default,
            color: theme.light.secondary,
            borderColor: theme.light.secondary,
            fontFamily: fontFamily.primary
        }
    }

    const handleContacts = (link) => {
        window.location.href = link
    }

    const openEditContact = (det, i) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editTitle: 'Edit Contact Info',
            editContent: det,
            editingFields: [
                {key: 'heading', label: 'Heading'},
                {key: 'text', label: 'Value'}
            ],
            path: `contactInfo[${i}].heading,contactInfo[${i}].text`,
            selector: 'contactDetails'
        }))
    }

    const openEditEducation = (det, i) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editTitle: 'Edit Education Info',
            editContent: det,
            editingFields: [
                {key: 'college', label: 'College'},
                {key: 'degree', label: 'Degree'},
                {key: 'stream', label: 'Stream'},
                {key: 'GPA', label: 'GPA'}
            ],
            path: `educationInfo[${i}].college,educationInfo[${i}].degree,educationInfo[${i}].stream,educationInfo[${i}].GPA`,
            selector: 'contactDetails'
        }))
    }

    return(
        <>
            {details && <Box style={styles.container}>
                <Typography variant="h1" style={getTextStyles(template, 'header1', {color: theme.light.secondary, fontFamily: fontFamily.primary})}>{details?.name}</Typography>
                <Grid container style={{padding: '3vmin'}}>
                {details?.contactInfo?.map((ele, i) => (
                    <Grid item xs={12} sm={12} md={4} lg={4} key={i} onDoubleClick={()=>openEditContact(ele, i)}>
                        <Typography variant="h3" style={getTextStyles(template, 'header3', {color: theme.light.tertiary, fontFamily: fontFamily.primary})}>{ele.heading}</Typography>
                        {ele.heading.toLowerCase() === "email" ? 
                            <Typography variant="h5" style={getTextStyles(template, 'header5', {color: theme.light.tertiary, fontFamily: fontFamily.primary, cursor: 'pointer'})} onClick={() => {window.location.href = `mailto:${ele.text}`}}>{ele.text}</Typography> : 
                            (ele.heading === "Phone" ?
                            <Typography variant="h5" style={getTextStyles(template, 'header5', {color: theme.light.tertiary, fontFamily: fontFamily.primary, cursor: 'pointer'})} onClick={() => {window.location.href = `tel:${ele.text}`}}>{ele.text}</Typography> :
                            <Typography variant="h5" style={getTextStyles(template, 'header5', {color: theme.light.tertiary, fontFamily: fontFamily.primary})}>{ele.text}</Typography>
                            )
                        }
                    </Grid> 
                ))}
                </Grid>

                <Box style={styles.button}>
                    <Button style={getButtonStyles('floating', styles.btnProps)} onClick={() => handleContacts(details?.linkedInLink)}><LinkedIn style={{fontSize: '2vmin'}}></LinkedIn></Button>
                    <Button style={getButtonStyles('floating', styles.btnProps)} onClick={() => handleContacts(details?.instagramLink)}><Instagram style={{fontSize: '2vmin'}}></Instagram></Button>
                    <Button style={getButtonStyles('floating', styles.btnProps)} onClick={() => handleContacts(details?.emailLink)}><Email style={{fontSize: '2vmin'}}></Email></Button>
                    <Button style={getButtonStyles('floating', styles.btnProps)} onClick={() => handleContacts(details?.githubLink)}><GitHub style={{fontSize: '2vmin'}}></GitHub></Button>
                </Box>

                <Divider style={{padding: '1vmin 0'}} />

                <Box style={{padding: '5vmin 2vmin'}}>
                    <Typography variant="h2" style={getTextStyles(template, 'header2', {color: theme.light.secondary, fontFamily: fontFamily.primary})}>Education</Typography>
                    <Grid container style={styles.education}>
                        {details?.educationInfo?.map((ele, i)=>(
                            <Grid item xs={12} sm={12} md={6} lg={6} key={i} onDoubleClick={()=>openEditEducation(ele, i)}>
                                <Typography variant="h3" style={{...getTextStyles(template, 'header3', {color: theme.light.tertiary, fontFamily: fontFamily.primary}), ...{paddingBottom: '2vmin'}}}>{ele.college}</Typography>
                                <Typography variant="h4" style={{...getTextStyles(template, 'header4', {color: theme.light.tertiary, fontFamily: fontFamily.primary}), ...{paddingBottom: '2vmin'}}}>{ele.degree}</Typography>
                                <Typography variant="h5" style={getTextStyles(template, 'header5', {color: theme.light.tertiary, fontFamily: fontFamily.primary})}>{ele.stream}</Typography>
                                {ele.GPA ? <Typography variant="h6" style={getTextStyles(template, 'header6', {color: theme.light.primary, fontFamily: fontFamily.primary})}>GPA: {ele.GPA}</Typography> : ''}
                            </Grid>
                        ))} 
                    </Grid>
                </Box>

                <Divider/>
                
                <Box style={{paddingTop: '2vmin'}}>
                    <Typography variant="h5" style={getTextStyles(template, 'header5', {color: theme.light.tertiary, fontFamily: fontFamily.primary})}>{details?.credits}</Typography>
                </Box>
            </Box>}
        </>
    )
}

export default Contact1;