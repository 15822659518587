import { Box, Button, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { getTextStyles } from '../../style/textStyles';
import { getButtonStyles } from '../../style/buttonStyles';
import { useDispatch, useSelector } from 'react-redux';
import { fontFamilySelector, templateSelector, themeSelector } from '../../services/ThemeSlice';
import { resourcesSelector } from '../../services/TemplateSlice';
import { setEditData } from '../../services/EditingSlice';

const Card1 = ({ details, refs }) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)
    const resources = useSelector(resourcesSelector)

    const dispatch = useDispatch();
    
    const styles = {
        image:{
            position: 'absolute',
            width: '12vw',
            height: '12vw',
            borderRadius: '100%',
            background: `center/cover url(${resources[details?.image]})`,
            border: `7px solid ${theme.light.tertiary}`,
            zIndex: 11,
            right: '5vmin',
            top: '-6vw'
        },
        content:{
            position: 'relative',
            boxShadow: `rgba(17, 12, 46, 0.15) 0px 48px 100px 0px`,
            background: theme.light.default,
            padding: '5vmin 10vmin',
            borderRadius: '20px'
        },
        title: {
            padding: '2vmin 0'
        },
        text: {
            padding: '2vmin 0'
        },
        button: {
            display: 'flex',
            justifyContent: 'center',
            margin: '2vw'
        },
        btnProps: {
            color: theme.light.primary,
            borderColor: theme.light.tertiary,
            fontFamily: fontFamily.primary,
            cursor: 'pointer'
        }
    }

    const handleClick = () => {
        refs.current[1].scrollIntoView({behavior: 'smooth'})
    }

    const openEditImage = () => {
        dispatch(setEditData({
            editType: 'image',
            editTitle: 'Edit Image',
            path: 'PROFILE_IMAGE'
        }))
    }

    const openEditAbout = (details) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editTitle: 'Edit About',
            editContent: details,
            regenerateSection: 'ABOUT_ME',
            editingFields: [
                {key: 'title', label: 'Name'},
                {key: 'text', label: 'About Me'},
            ],
            path: `sections[0].title,sections[0].text`,
            maxWidth: 'lg',
            fullWidth: true
        }))
    }

    return (
        <>
            {details && <Box>
                <Box style={styles.image} onDoubleClick={() => openEditImage()}></Box>
                <Box style={styles.content} onDoubleClick={() => openEditAbout(details)}>
                    <Typography variant="h1" style={getTextStyles(template, 'header1', {color: theme.light.secondary, fontFamily: fontFamily.primary})}>{details?.title}</Typography>
                    <Typography variant="body1" style={getTextStyles(template, 'body', {color: theme.light.primary, fontFamily: fontFamily.primary})}>{details?.text}</Typography>
                    <Box style={styles.button}>
                        <Button style={getButtonStyles('ghost', styles.btnProps)} onClick={() => handleClick()}>{details?.buttonText}</Button>
                    </Box>
                </Box>
            </Box>}
        </>
    )
}

Card1.propTypes = {
    details: PropTypes.shape({
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        buttonText: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired
    })
}

export default Card1;