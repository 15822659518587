import {Grid, Typography, Box} from "@mui/material";
import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import PropTypes from 'prop-types';

const Card7 = ({content}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const styles = {
        container:{
            display: 'flex',
            justifyContent: 'center',
            padding: '3vw 0'
        },
        text: {
            fontSize: 'calc(80px + 2vw)',
            fontFamily: fontFamily.primary,
            textDecoration: 'underline',
            textDecorationColor: theme.light.info,
            textDecorationThickness: '4px',
            color: theme.light.primary
        },
        image: {
            width: `calc(120px + 2vw)`,
            height: `calc(120px + 2vw)`,
            objectFit: `cover`,
        }
    }

    return(
        <Grid container style={styles.container}>
            {content?.map((ele)=> (
                <Grid item xs={12} sm={12} md={Math.floor(12/content.length)} lg={Math.floor(12/content.length)}>
                    <Box style={styles.container}>
                        {ele.isText ? 
                            <Typography style={styles.text}>{ele.data}</Typography>:
                            <img alt={ele.alt} src={ele.data} style={styles.image}/>
                        }
                    </Box>
                    {ele.header ? 
                        <Box>
                            {ele.header.split('\n').map((text)=>(
                                <Typography style={getTextStyles(template, 'header4', {fontFamily: fontFamily.primary, color: theme.light.info, textAlign: 'center'})}>{text}</Typography> 
                            ))}
                        </Box>
                    : ''}
                </Grid>
            ))}
        </Grid>
    )
}

Card7.propTypes = {
    content: PropTypes.array.isRequired,
}

export default Card7;