import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { useNavigate } from "react-router-dom";

const Card8 = ({content}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const navigate = useNavigate()

    const styles = {
        container: {
            border: `1px solid rgba(127,126,129,0.1)`,
            background: theme.light.secondary,
            margin: `2vw 0`,
            padding: '2vw'
        },
        imageWrap: {
            padding: window.innerWidth > 900 ? '0 2vw 0 0' : '0 0 3vw 0'
        },
        image : {
            width: '100%',
            height: 'auto',
            objectFit: 'contain'
        },
        btnWrap:{
            marginTop: '1vw'
        },
        btn: {
            fontFamily: fontFamily.primary,
            color: theme.light.info,
            textTransform: 'capitalize',
            fontSize: `calc(5px + 1vw)`,
            padding: 0 
        }
    }

    const clickHandler = () => {
        navigate(content?.link)
    }

    return(
        <Grid container style={styles.container}>
            <Grid item xs={12} sm={12} md={4} lg={4} style={styles.imageWrap}>
                <img alt={content?.image} src={content.image} style={styles.image}/>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} style={{textAlign: 'left'}}>
                <Typography style={getTextStyles(template, 'header3', {color: theme.light.info, fonFamily: fontFamily.primary})}>{content.header}</Typography>
                <Typography style={{...getTextStyles(template, 'body', {color: theme.light.info, fonFamily: fontFamily.secondary}), ...{textAlign: 'left'}}}>{content.text}</Typography>
                <Box style={styles.btnWrap}>
                    <Button style={styles.btn} onClick={clickHandler}>Read More</Button>
                </Box>
            </Grid>
        </Grid>
    )
}

Card8.propTypes = {
    content: PropTypes.shape({
        image: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
    })
}

export default Card8;