import { useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';

const Card11 = ({content, index}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const styles = {
        imageContainer: {
            border: `1px solid ${theme.light.tertiary}`,
            background: theme.light.secondary,
        },
        textContainer: {
            background: theme.light.default,
            border: `1px solid ${theme.light.tertiary}`,
        },
        textWrap: {
            padding: '5vw'
        },
        image: {
            width: '100%',
            height: 'auto',
            objectFit: 'contain'
        }
    }

    return(
        <Grid container>
            {window.innerWidth > 900 ? (index%2 === 0 ?
                <Grid item xs={12} sm={12} md={6} lg={6} style={styles.imageContainer}>
                    <img alt={content.alt} src={content.image} style={styles.image}/>
                </Grid> : '' ) : 
                <Grid item xs={12} sm={12} md={6} lg={6} style={styles.imageContainer}>
                    <img alt={content.alt} src={content.image} style={styles.image}/>
                </Grid>
            }
            <Grid item xs={12} sm={12} md={6} lg={6} style={styles.textContainer}>
                <Box style={styles.textWrap}>
                    <Typography style={getTextStyles(template, 'header2', {color: theme.light.primary, fontFamily: fontFamily.primary})}>{content.header}</Typography>
                    <Typography style={{...getTextStyles(template, 'header5', {color: theme.light.info, fontFamily: fontFamily.primary}), ...{textAlign: 'center'}}}>{content.subHeader}</Typography>
                    {content.text.split('\n').map((ele) => (
                        <Typography style={getTextStyles(template, 'body', {color: theme.light.info, fontFamily: fontFamily.secondary})}>{ele}</Typography>
                    ))}
                </Box>
            </Grid>
            {window.innerWidth > 900 && index%2 !== 0 ?
                <Grid item xs={12} sm={12} md={6} lg={6} style={styles.imageContainer}>
                    <img alt={content.alt} src={content.image} style={styles.image}/>
                </Grid> : ''
            }
        </Grid>
    )
}

Card11.propTypes = {
    content: PropTypes.shape({
        image: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        subHeader: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    }),
    index: PropTypes.number.isRequired
}

export default Card11;