import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import aboutImage from '../../assets/Template2/aboutImage.webp';
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { getTextStyles } from "../../style/textStyles";

const Card4 = ({contentDetails}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)

    const styles={
        container: {
            width: '100%',
            height: '700px',
            posiiton: 'relative',
            display: 'flex',
            justifyContent: 'center',
            background: window.innerWidth <= 900 ? `center / cover url(${aboutImage})` : 'none'
        },
        video: {
            display: window.innerWidth <= 900 ? 'none': 'block',
            position: 'relative',
            width: '100%',
            zIndex: 1
        },
        content: {
            position: 'absolute',
            top: 'calc(200px + 2vw)',
            right: '10vw',
            zIndex: 10,
            textAlign: 'center'
        },
        textProps: {
            color: theme.light.default,
            fontFamily: fontFamily.primary
        },
        banner: {
            position: 'absolute',
            background: theme.light.default,
            width: '80%',
            borderTop: `5px solid ${theme.light.info}`,
            borderBottom: `5px solid ${theme.light.info}`,
            textAlign: 'center',
            zIndex: 10,
            top: 'calc(700px + 2vw)'
        },
        button: {
            fontFamily: fontFamily.primary,
            fontSize: 'calc(10px + 1vw)',
            color: theme.light.default,
            width: '100%',
            borderRadius: 0
        },
        bannerContainer : {
            display: 'flex', 
            alignItems: 'center', 
            background: theme.light.primary
        },
        textWrap: {
            background: theme.light.default, 
            padding: '1vw'
        }
        
    }
    const autoPlay = contentDetails?.autoPlay || false;

    return(
        <Box style={styles.container}>
            {contentDetails?.overlay && <Box style={styles.content}>
                {contentDetails?.overlay?.textContent?.map(content => (
                    <Typography key={content?.id} style={getTextStyles(template, content?.textStyle !== undefined ? content?.textStyle : 'header6', {color: theme.light.default, fontFamily: fontFamily.primary})}>{content?.text}</Typography>
                ))}
            </Box>}
            {contentDetails?.tagline && <Box style={styles.banner}>
                <Grid container style={styles.bannerContainer}>
                    <Grid item xs={12} sm={12} md={9} lg={9} style={styles.textWrap}>
                        <Typography style={getTextStyles(template, contentDetails?.tagline?.textStyle, {color: theme.light.info, fontFamily: fontFamily.primary})}>{contentDetails?.tagline?.text}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Button style={styles.button}>{contentDetails?.tagline?.buttonText}</Button>
                    </Grid>
                </Grid>
            </Box>}

            <video autoPlay={autoPlay} muted loop style={styles.video}>
                <source src={contentDetails?.videoSrc} />
            </video>
        </Box>
    )
}

export default Card4;