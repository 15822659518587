import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getButtonStyles } from "../../style/buttonStyles";
import { getTextStyles } from "../../style/textStyles";
import Card3 from "../Card/Card3";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fontFamilySelector, templateSelector, themeSelector } from "../../services/ThemeSlice";
import { setEditData } from "../../services/EditingSlice";
import { authTokenSelector, editPortfilio } from "../../services/TemplateSlice";
import { Add } from "@mui/icons-material";
import { shiftElementsByPosition } from "../../editingComponents/Helpers";
import { useState } from "react";


const Project1 = ({details}) => {
    const theme = useSelector(themeSelector)
    const fontFamily = useSelector(fontFamilySelector)
    const template = useSelector(templateSelector)
    const authToken = useSelector(authTokenSelector);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [dragState, setDragState] = useState(null);

    const dispatch = useDispatch();

    const styles = {
        imageCard: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '5vw'
        },
        btnProps: {
            backgroundColor: theme.light.tertiary,
            color: theme.light.info,
            fontFamily: fontFamily.primary
        }
    }

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/project`)
    }

    const openEditImage = (path) => {
        dispatch(setEditData({
            editType: 'image',
            editTitle: 'Edit Image',
            path: path
        }))
    }

    const openEditProject = (project, i, arrayContent) => {
        dispatch(setEditData({
            editType: 'multi-text',
            editContent: project,
            editSubType: typeof(i) === 'number' ? '' : 'array',
            arrayContent: arrayContent,
            arrayIndex: i,
            editingFields: [
                {key: 'title', label: 'Title'},
                {key: 'intro', label: 'Description'}
            ],
            editTitle: 'Edit Project Details',
            path: `sections[2].projects[${i}].title,sections[2].projects[${i}].intro`,
            deletePath: `sections[2].projects`,
        }))
    }

    return(
        <Box>
            <Grid container style={{paddingTop: '3vw'}}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h1" style={getTextStyles(template, 'header1', {color: theme.light.secondary, fontFamily: fontFamily.primary})}>{details?.header}
                    {Boolean(authToken) && authToken === queryParams.get('authToken') && <IconButton style={{marginBottom: 10, color: '#000', marginLeft: 10}} onClick={() => openEditProject(details?.content)}><Add/></IconButton>}
                    </Typography>
                </Grid>
            </Grid>

            {details && <Box>
                {details?.projects?.map((ele, i)=> (
                    <Grid 
                        container 
                        spacing={2} 
                        key={i}
                        style={{cursor: Boolean(authToken) && authToken === queryParams.get('authToken') ? 'move' : 'default'}}
                        draggable={Boolean(authToken) && authToken === queryParams.get('authToken')} 
                        onDragEnd={(event) => {
                            dispatch(editPortfilio(`sections[2].projects`, shiftElementsByPosition(details?.projects, i + 1, dragState + 1), queryParams.get('authToken'), null, 'array'));
                        }} 
                        onDragOver={() => setDragState(i)}>
                        <Grid item xs={12} sm={12} md={12} lg={5} style={styles.imageCard} onDoubleClick={() => openEditImage(ele.image)}>
                            <Card3 image={ele.image}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={7} style={styles.imageCard}>
                            <Box onDoubleClick={() => openEditProject(ele, i, details?.projects)}>
                                <Typography variant="h2" style={{...getTextStyles(template, 'header2', {color: theme.light.secondary, fontFamily: fontFamily.primary}), ...{paddingBottom: '2vw'}}}>{ele.title}</Typography>
                                <Typography variant="body" style={getTextStyles(template, 'body', {color: theme.light.primary, fontFamily: fontFamily.primary})}>{ele.intro}</Typography> 
                                <Box style={{margin: '5vw 0 0 0'}}>
                                    <Button style={getButtonStyles('rounded', styles.btnProps)} onClick={handleClick}>{ele.btnText}</Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                ))}
            </Box>}
        </Box>
    )
}

export default Project1;